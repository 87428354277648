<template>
  <div>
    <v-select
      :getOptionLabel="option => `${option.firstName} ${option.lastName}`"
      :options="Object.values(users)
                    .sort((v1, v2) =>
                        `${v1.firstName} ${v1.lastName} ${v1.email}`.localeCompare(
                            `${v2.firstName} ${v2.lastName} ${v2.email}`))
                    .slice(0, 10)"
      placeholder="Zacznij pisać"
      v-model="selectedUser"
      @search="onInput"
    >
      <template #option="{ firstName, lastName, email }">
        {{ firstName }} {{ lastName }} <small>({{ email }})</small>
      </template>
      <template #selected-option="{ firstName, lastName, email }">
        <div class="text-center">{{ firstName }} {{ lastName }} <small>({{ email }})</small></div>
      </template>
      <!-- eslint-disable-next-line vue/no-unused-vars  -->
      <template #no-options="{ search, searching, loading }">
        {{ !searching && !loading ? "Brak pasujących użytkowników" : "" }}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
  name: 'UserSelect',
  components: {
    vSelect,
  },
  watch: {
    modelValue(newValue) {
      this.selectedUser = newValue
    },
    selectedUser(newValue) {
      this.$emit('update:modelValue', newValue)
    }
  },
  data() {
    return {
      input: '',
      loadingFunc: null,
      requests: {
        firstName: {
          abortController: null,
        },
        lastName: {
          abortController: null,
        },
      },
      selectedUser: null,
      typingTimeout: null,
      users: {},
    }
  },
  emits: [
    'update:modelValue'
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      default: null,
    },
  },
  beforeUnmount() {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout)
      this.typingTimeout = null
    }
    for (const request of Object.values(this.requests)) {
      if (request.abortController) {
        request.abortController.abort()
      }
    }
  },
  methods: {
    getUsers() {
      this.users = {}
      for (const [key, request] of Object.entries(this.requests)) {
        if (request.abortController) {
          request.abortController.abort()
        }
        request.abortController = new AbortController()
        const params = {
          itemsPerPage: 10,
        }
        params[key] = this.input
        this.$http.get(
          '/api/users',
          {
            params,
            signal: request.abortController.signal,
          })
          .then(response => {
            if (response.data) {
              const users = {...this.users}
              response.data.forEach(user => {
                users[user.id] = user
              })
              this.users = users
            }
            if (this.loadingFunc) {
              this.loadingFunc(false)
            }
          })
          .catch(() => {
            if (this.loadingFunc) {
              this.loadingFunc(false)
            }
          })
          .then(() => {
            request.abortController = null
          })
      }
    },
    onInput(searchString, loadingFunc) {
      if (!searchString) {
        return
      }
      this.input = searchString
      this.loadingFunc = loadingFunc
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout)
      }
      this.typingTimeout = setTimeout(
        () => {
          this.typingTimeout = null
          this.loadingFunc(true)
          this.getUsers()
        },
        500)
    },
  },
};
</script>
