<template>
  <div class="container h-100 w-100">
    <h1 class="text-center mb-3">
      Błąd weryfikacji
    </h1>
    <p class="text-center"><b-icon-x-circle-fill class="text-danger fs-1" /></p>
    <p class="text-center" v-if="firstName && lastName && email">
      Próba weryfikacji użytkownika <b>{{ firstName }} {{ lastName }}</b>, posługującego się
      adresem email <b>{{ email }}</b>, nie powiodła się.
    </p>
    <p class="text-center" v-else>
      Próba weryfikacji użytkownika nie powiodła się.
    </p>
    <p class="text-center" v-if="reason === 'expired_signature'">
      Link weryfikacyjny stracił ważność. Aby uzyskać nowy link weryfikacyjny
      <router-link :to="'/login'">zaloguj się</router-link> i wygeneruj nowy link weryfikacyjny.
    </p>
    <p class="text-center" v-if="reason === 'wrong_link'">
      Użyty link weryfikacyjny jest niepoprawny.
    </p>
   </div>
</template>

<script>
import {
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

export default {
  name: 'FailedVerification',
  components: {
    BIconXCircleFill,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    firstName: {
      type: String,
      default: '',
    },
    lastName: {
      type: String,
      default: '',
    },
    reason: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>
