function normalizeName(name) {
  return name.trim().replace(/\s+/g, ' ')
}

function normalizePhoneNumber(phoneNumber) {
  let result = phoneNumber.replace(/(\D)/g, () => (''))
  if (result.length > 9) {
    result = result.substring(0, 9)
  }
  return result
}

function printableDate(date) {
  return date.toLocaleDateString(
    undefined,
    {
      weekday: 'short',
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    })
}

function printableTime(date) {
  return date.toLocaleTimeString(
    undefined,
    {
      hour: '2-digit',
      minute: '2-digit',
    })
}

function toUppercaseFirstWordLetter(string) {
  return string.replace(/(?:\s|^)(\S)/g, (v) => (v.toUpperCase()))
}

function slotIdxToPrintableHour(idx) {
  const hours = Math.floor(idx / 2 + 7)
    .toString()
    .padStart(2, '0')
  const minutes = idx % 2 == 0 ? '00' : '30'
  return `${hours}:${minutes}`
}

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default {
  normalizeName,
  normalizePhoneNumber,
  printableDate,
  printableTime,
  slotIdxToPrintableHour,
  toUppercaseFirstWordLetter,
  validateEmail,
}
