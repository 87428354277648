<template>
  <div class="d-flex justify-content-center">
    <button type="btn" class="btn btn-primary me-1" @click="moveByDays(-1)">
      <b-icon-caret-left-fill />
    </button>
    <Datepicker
      autoApply
      :clearable="false"
      :enableTimePicker="false"
      :format="formatDate"
      :inline="!isCompact"
      locale="pl-PL"
      :modelValue="modelValue"
      ref="picker"
      @update:modelValue="onDateUpdate"
    />
    <button type="btn" class="btn btn-primary ms-1" @click="moveByDays(+1)">
      <b-icon-caret-right-fill />
    </button>
  </div>
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import {
  BIconCaretLeftFill,
  BIconCaretRightFill,
} from 'bootstrap-icons-vue'

import utils from '../libs/utils'

export default {
  name: 'Calendar',
  components: {
    BIconCaretLeftFill,
    BIconCaretRightFill,
    Datepicker,
  },
  props: {
    modelValue: {
      type: Date,
      default: new Date(),
    },
    isCompact: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:modelValue',
  ],
  methods: {
    formatDate(date) {
      return utils.printableDate(date)
    },
    onDateUpdate(date) {
      this.$emit('update:modelValue', date)
    },
    moveByDays(days) {
      const newDate = new Date(this.modelValue)
      newDate.setDate(this.modelValue.getDate() + days);
      this.onDateUpdate(newDate)
    }
  },
};
</script>
