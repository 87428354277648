<template>
  <div class="container h-100 w-100">
    <h1 class="text-center mb-3">
      Blokada konta
    </h1>
    <p class="text-center"><b-icon-dash-circle-fill class="text-danger fs-1" /></p>
    <p class="text-center">
      Niestety konto użytkownika <b>{{ userFullName }}</b>, posługującego się
      adresem email <b>{{ email }}</b>, zostało zablokowane przez Administratora systemu.
    </p>
    <p class="text-center">
      W celu odblokowania konta skontaktuj się z Administratorem systemu.
    </p>
   </div>
</template>

<script>
import {
  BIconDashCircleFill,
} from 'bootstrap-icons-vue'

export default {
  name: 'BlockedAccount',
  components: {
    BIconDashCircleFill,
  },
  computed: {
    email() {
      return this.$store.state.user.email
    },
    userFullName() {
      return this.$store.getters['user/fullName']
    },
  },
  data() {
    return {
    }
  },
}
</script>
