<template>
  <div class="d-flex justify-content-center h-100 w-100">
    <div class="container row">
      <div class="col-12 col-md-6 d-flex align-items-start justify-content-center">
        <img
          src="@/assets/korty_arturowek_wizytowka.jpg"
          class="img-fluid"
        >
      </div>
      <div class="col-12 col-md-6">
        <form @submit.prevent="onLogin">
          <div class="mb-2">
            <h3 class="text-center">Logowanie</h3>
            <p class="text-center">
              Musisz zalogować się żeby korzystać z systemu rezerwacji.
              Jeśli nie posiadasz jeszcze konta w systemie,
              <router-link class="text-nowrap" :to="'/sign_up'">zarejestruj się</router-link>.
            </p>
          </div>

          <!-- Email input -->
          <span class="text-danger" v-if="emailWarning">
            <BIconExclamationTriangle /> {{ emailWarning }}
          </span>
          <div class="form-floating mb-2">
            <input
              type="email"
              id="loginFormEmail"
              class="form-control"
              placeholder="Email"
              v-model="email"
              @input="onEmailInput"
            />
            <label class="form-label" for="loginFormEmail">Email</label>
          </div>

          <!-- Password input -->
          <span class="text-danger" v-if="passwordWarning">
            <BIconExclamationTriangle /> {{ passwordWarning }}
          </span>
          <password-input
            id="loginFormPassword"
            label="Hasło"
            v-model="password"
            @update:modelValue="onPasswordInput"
          />
          <div class="d-flex justify-content-end">
            <router-link to="/send_password_reset_link">Nie pamiętam hasła</router-link>
          </div>

          <!-- Submit button -->
          <div class="d-flex justify-content-center mt-4">
            <button type="submit" class="btn btn-primary btn-lg">Zaloguj</button>
          </div>
        </form>
      </div>
    </div>
    <modal-template header="Logowanie" id="logIn__modal" ref="logIn__modal">
      <template v-slot:body>
        <div
          class="row justify-content-center"
          v-if="logInState === 'in_progress'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
        <div
          class="row justify-content-center"
          v-if="logInState === 'failed'"
        >
          <b-icon-x-circle-fill class="text-danger fs-1 me-2" />
          <p class="text-center">
            Logowanie nie powiodło się, ponieważ <b>{{ failureReason }}</b>.
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <return-button v-if="logInState !== 'in_progress'" @click="onLogInModalButtonClicked" />
      </template>
    </modal-template>
  </div>
</template>

<script>
import {
  BIconExclamationTriangle,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import {
  RouterLink,
} from 'vue-router'

import auth from '../libs/auth'

import ModalTemplate from '../components/ModalTemplate.vue'
import PasswordInput from '../components/PasswordInput.vue'
import ReturnButton from '../components/ReturnButton.vue'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default {
  name: 'Login',
  components: {
    BIconExclamationTriangle,
    BIconXCircleFill,
    ModalTemplate,
    PasswordInput,
    ReturnButton,
    RouterLink,
  },
  data() {
    return {
      email: '',
      emailWarning: '',
      failureReason: '',
      logInState: 'in_progress',
      password: '',
      passwordWarning: '',
    }
  },
  methods: {
    onLogin() {
      let formValid = true;
      if (!this.email) {
        formValid = false
        this.emailWarning = 'Adres email nie może być pusty'
      } else if(!validateEmail(this.email)) {
        formValid = false
        this.emailWarning = 'Niepoprawny adres email'
      }
      if (!this.password) {
        formValid = false
        this.passwordWarning = 'Hasło nie może być puste';
      }
      if (formValid) {
        this.logInState = 'in_progress'
        this.$refs['logIn__modal'].show()
        this.$http.post(
          '/api/login_check',
          {
            username: this.email,
            password: this.password,
          }
        ).then(response => {
          if (response.data.token) {
            auth.setAccessToken(response.data.token)
            this.$store.commit('user/setUserData', response.data.user)
            this.$refs['logIn__modal'].hide();
            this.$router.replace('/')
          } else {
            this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
            this.logInState = 'failed'
          }
        }).catch(error => {
          if (error.response) {
            switch (error.response.status) {
              case 401: // unauthorized
                this.failureReason = 'podano nieprawidłowy email i/lub hasło'
                break
              default:
                this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
                break
            }
          } else {
            this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
          }
          this.logInState = 'failed'
        })
      }
    },
    onEmailInput() {
      this.emailWarning = ''
    },
    onLogInModalButtonClicked() {
      this.$refs['logIn__modal'].hide()
    },
    onPasswordInput() {
      this.passwordWarning = ''
    },
  },
}
</script>
