<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid justify-content-start">
      <router-link class="navbar-brand me-auto order-0" :to="{ path: '/' }">Korty Arturówek</router-link>
      <button
        class="navbar-toggler ms-2 order-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse order-3 order-lg-1" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link
              active-class="active"
              aria-current-value="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              :class="`nav-link ${isLogged && !isBlocked ? '' : 'disabled'}`"
              :to="{ path: '/' }"
              @click="$router.push('/')"
            >
              Grafik
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              active-class="active"
              aria-current-value="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              :class="`nav-link ${isLogged && !isBlocked ? '' : 'disabled'}`"
              :to="{ path: '/my_reservations' }"
              @click="$router.push('/my_reservations')"
            >
              Moje rezerwacje
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              active-class="active"
              aria-current-value="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              :class="`nav-link ${isLogged && !isBlocked ? '' : 'disabled'}`"
              :to="{ path: '/my_profile' }"
              @click="$router.push('/my_profile')"
            >
              Mój profil
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              active-class="active"
              aria-current-value="page"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              class="nav-link"
              v-if="isAdmin && !isBlocked"
              :to="{ path: '/users' }"
              @click="$router.push('/users')"
            >
              Użytkownicy
            </router-link>
          </li>
          <li><hr class="dropdown-divider"></li>
          <li class="nav-item d-inline-flex">
            <a
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
              :class="`nav-link me-auto ${isLogged ? '' : 'disabled'}`"
              href="/login"
              @click="onLogout"
            >
              Wyloguj
            </a>
            <div
              class="d-inline-flex d-md-none flex-nowrap align-items-center"
              v-if="isLogged"
            >
              <div class="text-center lh-1">
                <b>{{userName }}</b><br>
                <small>{{ userType }}</small><br>
              </div>
              <img
                class="rounded-circle ms-1"
                :src="gravatarLink"
              />
            </div>
          </li>
        </ul>
      </div>
      <div
        class="d-flex d-none d-md-inline-flex align-items-center order-1 order-lg-3 ms-auto"
        v-if="isLogged"
      >
        <div class="text-center lh-1">
          <b>{{ userName }}</b><br>
          <small>{{ userType }}</small><br>
        </div>
        <img
          class="rounded-circle ms-1"
          :src="gravatarLink"
        />
      </div>
    </div>
  </nav>
</template>

<script>
import md5 from 'md5'
import { RouterLink } from 'vue-router'
import auth from '../libs/auth'

export default {
  name: 'NavBar',
  components: {
    RouterLink,
  },
  computed: {
    gravatarLink() {
      return `https://www.gravatar.com/avatar/${md5(this.$store.state.user.email)}?d=retro&s=40`
    },
    isAdmin() {
      const user = this.$store.state.user
      return user.roles && user.roles.some(v => (v === 'ROLE_ADMIN'))
    },
    isBlocked() {
      const user = this.$store.state.user
      return user.roles && user.roles.some(v => (v === 'ROLE_BLOCKED_USER'))
    },
    isLogged() {
      return this.$store.state.user.id ? true : false
    },
    userName() {
      return this.$store.getters['user/fullName']
    },
    userType() {
      const userRoles = this.$store.state.user.roles;
      if (!userRoles) {
        return 'Anonimowy'
      }
      if (userRoles.some(v => (v === 'ROLE_ADMIN'))) {
        return 'Administrator'
      }
      return 'Użytkownik'
    },
  },
  methods: {
    onLogout() {
      auth.clearCredentials()
      this.$store.commit('user/setUserData', null)
      this.$router.push('/login')
    }
  },
}
</script>
