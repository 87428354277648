function getAccessToken() {
  return localStorage.getItem('accessToken')
}

function setAccessToken(token) {
  localStorage.setItem('accessToken', token)
}

function clearCredentials() {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('userData')
}

function clearUserData() {
  localStorage.removeItem('userData')
}

function isAuthenticated() {
  return localStorage.getItem('accessToken') ? true : false
}

function storeUserData(data) {
  localStorage.setItem('userData', JSON.stringify(data))
}

function getUserData() {
  const serializedUserData = localStorage.getItem('userData')
  if (!serializedUserData) {
    return null;
  }
  return JSON.parse(serializedUserData)
}

function doesUserHaveRole(role) {
  const userData = getUserData()
  return userData && userData.roles.some(v => (v === role))
}

function isUser() {
  return isAuthenticated() && doesUserHaveRole('ROLE_USER')
}

function isBlockedUser() {
  return isAuthenticated() && doesUserHaveRole('ROLE_BLOCKED_USER')
}

function isVerifiedUser() {
  return isAuthenticated() && doesUserHaveRole('ROLE_VERIFIED_USER')
}

function isAdmin() {
  return isAuthenticated() && doesUserHaveRole('ROLE_ADMIN')
}

export default {
  clearCredentials,
  clearUserData,
  getAccessToken,
  getUserData,
  isAdmin,
  isAuthenticated,
  isUser,
  isBlockedUser,
  isVerifiedUser,
  setAccessToken,
  storeUserData,
}
