<template>
  <div class="container h-100 w-100">
    <h1 class="text-center mb-3">
      Niezweryfikowany użytkownik
    </h1>
    <p class="text-center"><b-icon-exclamation-triangle-fill class="text-warning fs-1" /></p>
    <p class="text-center">
      Użytkownik <b>{{ $store.getters['user/fullName'] }}</b>, posługujący się adresem email
      <b>{{ $store.state.user.email }}</b>, nie został jeszcze zweryfikowany.
    </p>
    <p class="text-center">
      Aby zakończyć proces weryfikacji użytkownika, kliknij w link aktywacyjny wysłany na adres
      email podany podczas rejestracji.
    </p>
    <p class="text-center">
      Wiadomość weryfikacyjną możesz wygenerować ponownie za pomocą poniższego przycisku:
    </p>
    <p class="text-center">
      <button
        class="btn btn-primary"
        @click="sendVerificationEmail"
      >
        Wyślij wiadomość
      </button>
    </p>

    <modal-template
      header="Wysyłanie wiadomości weryfikacyjnej"
      id="sendVerificationEmail__modal"
      ref="sendVerificationEmail__modal"
    >
      <template v-slot:body>
        <div
          class="row justify-content-center"
          v-if="sendVerificationEmailState === 'in_progress'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
        <div
          class="row justify-content-center"
          v-if="sendVerificationEmailState === 'succeeded'"
        >
          <b-icon-check-circle-fill class="text-success fs-1 me-2" />
          <p class="text-center">
            Wiadomość z linkiem weryfikacyjnym wysłano na adres email
            <b>{{ $store.state.user.email }}</b>. Postępuj zgodnie ze wskazówkami zawartymi we
            wiadomości, aby dokonać weryfikacji adresu.
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-if="sendVerificationEmailState === 'failed'"
        >
          <b-icon-x-circle-fill class="text-danger fs-1 me-2" />
          <p class="text-center">
            Niestety nie udało się wysłać wiadomości weryfikacyjnej, ponieważ
            <b>{{ failureReason }}</b>.
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <return-button
          v-if="sendVerificationEmailState !== 'in_progress'"
          @click="onSendEmailVerificationModalButtonClick"
        />
      </template>
    </modal-template>
  </div>
</template>

<script>
import {
  BIconCheckCircleFill,
  BIconExclamationTriangleFill,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import ModalTemplate from '../components/ModalTemplate.vue'
import ReturnButton from '../components/ReturnButton.vue'

export default {
  name: 'UnverifiedUser',
  components: {
    BIconCheckCircleFill,
    BIconExclamationTriangleFill,
    BIconXCircleFill,
    ModalTemplate,
    ReturnButton,
  },
  props: {
  },
  computed: {
  },
  data() {
    return {
      failureReason: null,
      sendVerificationEmailState: 'idle',
    }
  },
  methods: {
    onSendEmailVerificationModalButtonClick() {
      this.$refs['sendVerificationEmail__modal'].hide()
      this.signUpState = 'idle'
    },
    sendVerificationEmail() {
      this.sendVerificationEmailState = 'in_progress'
      this.$refs['sendVerificationEmail__modal'].show()
      this.$http.post('/api/send_verification_email'
      ).then(() => {
        this.sendVerificationEmailState = 'succeeded'
      }).catch(error  => {
        if (error.response) {
          switch (error.response.status) {
            case 401: // unauthorized
              this.$refs['sendVerificationEmail__modal'].hide()
              return
            default:
              this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
              break
          }
        } else {
          this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
        }
      })
    },
  },
}
</script>
