<template>
  <div
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    :aria-labelledby="labelId"
    :ref="id"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header bg-primary bg-gradient text-white justify-content-center">
          <h5 class="modal-title" :id="labelId">{{ header }}</h5>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
        <div class="modal-footer bg-light">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ModalTemplate',
  components: {
  },
  props: {
    header: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
  },
  computed: {
    labelId() {
      return `${this.id}__label`
    },
  },
  methods: {
    hide() {
      this.$bootstrap.Modal.getOrCreateInstance(this.$refs[this.id]).hide()
    },
    show() {
      this.$bootstrap.Modal.getOrCreateInstance(this.$refs[this.id]).show()
    },
  },
};
</script>
