<template>
  <div class="row justify-content-center h-100 w-100">
    <div class="col-md-5 col-lg-4 col-xl-3 order-2 order-md-1">
      <form @submit.prevent="onSignUp">

        <div class="mb-2">
          <h3 class="text-center">
            Rejestracja nowego użytkownika
          </h3>
          <p class="text-center">
            Jeżeli posiadasz już konto w systemie,
            <router-link class="text-nowrap" to="/login">zaloguj się</router-link>.
          </p>
        </div>

        <span class="text-danger" v-if="firstNameWarning">
          <b-icon-exclamation-triangle /> {{ firstNameWarning }}
        </span>
        <div class="form-floating mb-2">
          <input
            class="form-control"
            id="signUpFormFirstName"
            placeholder="Imię"
            type="text"
            v-model="firstName"
            @input="onFirstNameInput"
          />
          <label class="form-label" for="signUpFormFirstName">Imię</label>
        </div>

        <span class="text-danger" v-if="lastNameWarning">
          <b-icon-exclamation-triangle /> {{ lastNameWarning }}
        </span>
        <div class="form-floating mb-2">
          <input
            class="form-control"
            id="signUpFormLastName"
            placeholder="Nazwisko"
            type="text"
            v-model="lastName"
            @input="onLastNameInput"
          />
          <label class="form-label" for="signUpFormLastName">Nazwisko</label>
        </div>

        <span class="text-danger" v-if="emailWarning">
          <b-icon-exclamation-triangle /> {{ emailWarning }}
        </span>
        <div class="form-floating mb-2">
          <input
            class="form-control"
            id="signUpFormEmail"
            placeholder="Email"
            type="email"
            v-model="email"
            @input="onEmailInput"
          />
          <label class="form-label" for="signUpFormEmail">Email</label>
        </div>

        <span class="text-danger" v-if="phoneNumberWarning">
          <b-icon-exclamation-triangle /> {{ phoneNumberWarning }}
        </span>
        <div class="input-group mb-2">
          <span class="input-group-text">+48</span>
          <div class="form-floating flex-fill">
          <input
            class="form-control "
            id="signUpFormPhoneNumber"
            placeholder="Nr telefonu (opcjonalne)"
            type="text"
            v-model="phoneNumber"
            @input="onPhoneNumberInput"
          />
          <label class="form-label" for="signUpFormPhoneNumber">Nr telefonu (opcjonalnie)</label>
          </div>
        </div>

        <span class="text-danger" v-if="password1Warning">
          <b-icon-exclamation-triangle /> {{ password1Warning }}
        </span>
        <password-input
          class="mb-2"
          id="signUpFormPassword1"
          label="Hasło"
          v-model="password1"
          @update:modelValue="onPassword1Input"
        />

        <span class="text-danger" v-if="password2Warning">
          <b-icon-exclamation-triangle /> {{ password2Warning }}
        </span>
        <password-input
          class="mb-2"
          id="signUpFormPassword2"
          label="Powtórz hasło"
          v-model="password2"
          @update:modelValue="onPassword2Input"
        />

        <div class="d-flex justify-content-center mt-4">
          <button type="submit" class="btn btn-primary btn-lg">Zarejestruj</button>
        </div>
      </form>
    </div>

    <div class="col-md-7 col-lg-7 col-xl-7 d-flex align-items-center order-1 order-lg-2 mh-lg-100">
      <img src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-registration/draw1.webp" class="img-fluid" alt="Sample image">
    </div>
  </div>
  <modal-template header="Rejestrowanie użytkownika" id="signUp__modal" ref="signUp__modal">
    <template v-slot:body>
      <div
        class="row justify-content-center"
        v-if="signUpState === 'in_progress'"
      >
        <div class="spinner-border text-info" role="status" />
      </div>
      <div
        class="row justify-content-center"
        v-if="signUpState === 'succeeded'"
      >
        <b-icon-check-circle-fill class="text-success fs-1 me-2" />
        <p class="text-center">
          Rejestracja użytkownika przebiegła pomyślnie. Aby korzystać z systemu, musimy
          jeszcze zweryfikować adres email podany podczas rejestracji. Wiadomość weryfikacyjna
          została wysłana na podany adres. Postępuj zgodnie ze wskazówkami zawartymi we
          wiadomości, aby dokonać weryfikacji adresu.
        </p>
      </div>
      <div
        class="row justify-content-center"
        v-if="signUpState === 'failed'"
      >
        <b-icon-x-circle-fill class="text-danger fs-1 me-2" />
        <p class="text-center">
          Niestety nie udało się zarejestrować użytkownika, ponieważ
          <b>{{ failureReason }}</b>.
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <return-button v-if="signUpState !== 'in_progress'" @click="onRegisteringModalButtonClick" />
    </template>
  </modal-template>
</template>

<script>
import {
  BIconCheckCircleFill,
  BIconExclamationTriangle,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import utils from '@/libs/utils'

import ModalTemplate from '../components/ModalTemplate.vue'
import PasswordInput from '../components/PasswordInput.vue'
import ReturnButton from '../components/ReturnButton.vue'

export default {
  name: 'SignUp',
  components: {
    BIconCheckCircleFill,
    BIconExclamationTriangle,
    BIconXCircleFill,
    ModalTemplate,
    PasswordInput,
    ReturnButton,
  },
  data() {
    return {
      email: '',
      emailWarning: null,
      failureReason: '',
      firstName: '',
      firstNameWarning: null,
      lastName: '',
      lastNameWarning: null,
      password1: '',
      password1Warning: null,
      password2: '',
      password2Warning: null,
      phoneNumber: '',
      phoneNumberWarning: null,
      signUpState: 'idle',
    }
  },
  methods: {
    onEmailInput() {
      this.emailWarning = null
    },
    onFirstNameInput() {
      this.firstNameWarning = null
      this.firstName = utils.toUppercaseFirstWordLetter(this.firstName)
    },
    onLastNameInput() {
      this.lastNameWarning = null
      this.lastName = utils.toUppercaseFirstWordLetter(this.lastName)
    },
    onPassword1Input() {
      this.password1Warning = null
      if (this.password2 && this.password1 !== this.password2) {
        this.password2Warning = 'Hasła muszą być identyczne'
      } else {
        this.password2Warning = null
      }
    },
    onPassword2Input() {
      if (this.password2 && this.password1 !== this.password2) {
        this.password2Warning = 'Hasła muszą być identyczne'
      } else {
        this.password2Warning = null
      }
    },
    onPhoneNumberInput() {
      this.phoneNumber = utils.normalizePhoneNumber(this.phoneNumber)
      this.phoneNumberWarning = this.phoneNumber.length === 0 || this.phoneNumber.length === 9 ?
          null : 'Numer musi mieć 9 cyfr'
    },
    onRegisteringModalButtonClick() {
      this.$refs['signUp__modal'].hide()
      const succeeded = this.signUpState === 'succeeded'
      this.signUpState = 'idle'
      if (succeeded) {
        this.$router.push('/login')
      }
    },
    onSignUp() {
      let formValid= true;
      this.email = this.email.trim()
      if (!this.email) {
        formValid = false
        this.emailWarning = 'Adres email nie może być pusty'
      } else if(!utils.validateEmail(this.email)) {
        formValid = false
        this.emailWarning = 'Niepoprawny adres email'
      }
      this.firstName = utils.normalizeName(this.firstName)
      if (!this.firstName) {
        formValid = false
        this.firstNameWarning = 'Imię nie może być puste'
      }
      this.lastName = utils.normalizeName(this.lastName)
      if (!this.lastName) {
        formValid = false
        this.lastNameWarning = 'Nazwisko nie może być puste'
      }
      if (this.phoneNumberWarning) {
        formValid = false
      }
      if (!this.password1) {
        formValid = false
        this.password1Warning = 'Hasło nie może być puste'
      }
      if (this.password1 !== this.password2) {
        formValid = false
        this.password2Warning = 'Hasła muszą być identyczne'
      }
      if (formValid) {
        this.signUpState = 'in_progress'
        this.$refs['signUp__modal'].show()
        const params = {
          email: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
          password: this.password1,
        }
        if (this.phoneNumber) {
          params.phoneNumber = this.phoneNumber
        }
        this.$http.post('/api/users', params)
          .then(response => {
            if (response.status == 200) {
              this.signUpState = 'failed'
              this.failureReason =
                'podany adres email jest już zarejestrowany w systemie'
            } else {
              this.signUpState = 'succeeded'
            }
          })
          .catch(error  => {
            if (error.response) {
              switch (error.response.status) {
                case 401: // unauthorized
                  this.$refs['signUp__modal'].hide()
                  return
                default:
                  this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
                  break
              }
            } else {
              this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
            }
          })
      }
    },
  },
}
</script>
