<template>
  <div class="d-flex h-100">
    <div class="flex-grow-0 flex-shrink-1 d-none d-md-block me-3">
      <calendar v-model="date" />
    </div>
    <div
      class="d-flex flex-column
             flex-grow-1 flex-shrink-1
             overflow-auto
             h-100
             position-relative"
    >
      <div class="flex-grow-0 flex-shrink-1
                  d-inline-flex d-md-none
                  justify-content-center
                  pb-2" >
        <calendar v-model="date" :isCompact="true" />
      </div>
      <div class="flex-grow-1 flex-shrink-1 overflow-auto" >
        <Schedule  id="Schedule" :date="date" />
      </div>
    </div>
  </div>
</template>

<script>
import Calendar from '@/components/Calendar.vue'
import Schedule from '@/components/Schedule.vue'

export default {
  name: 'App',
  components: {
    Calendar,
    Schedule,
  },
  data() {
    return {
      date: new Date(),
    }
  },
}
</script>
