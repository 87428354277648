import {
  createRouter as createVueRouter,
  createWebHistory,
} from 'vue-router'

import auth from './auth'
import BlockedAccount from '@/views/BlockedAccount.vue'
import ChangePassword from '@/views/ChangePassword.vue'
import FailedVerification from '@/views/FailedVerification.vue'
import Login from '@/views/Login.vue'
import MyProfile from '@/views/MyProfile.vue'
import MyReservations from '@/views/MyReservations.vue'
import Schedules from '@/views/Schedules.vue'
import SendPasswordResetLink from '@/views/SendPasswordResetLink.vue'
import SignUp from '@/views/SignUp.vue'
import SuccessfulVerification from '@/views/SuccessfulVerification.vue'
import UnverifiedUser from '@/views/UnverifiedUser.vue'
import Users from '@/views/Users.vue'

export function createRouter() {
  const routes = [
    { path: '/', component: Schedules },
    { path: '/blocked_account', component: BlockedAccount },
    { path: '/my_profile', component: MyProfile },
    { path: '/my_reservations', component: MyReservations },
    { path: '/login', component: Login },
    { path: '/sign_up', component: SignUp },
    { path: '/users', component: Users },
    {
      path: '/successful_verification',
      component: SuccessfulVerification,
      props: route => ({
        email: route.query.email,
        firstName: route.query.firstName,
        lastName: route.query.lastName,
      }),
      beforeEnter: (to, from, next) => {
        if (to.query.email && to.query.firstName && to.query.lastName) {
          next();
        } else {
          next({ path: '/', replace: true, })
        }
      },
    },
    {
      path: '/failed_verification',
      component: FailedVerification,
      props: route => ({
        email: route.query.email,
        firstName: route.query.firstName,
        lastName: route.query.lastName,
        reason: route.query.reason,
      }),
      beforeEnter: (to, from, next) => {
        if (to.query.reason) {
          next();
        } else {
          next({ path: '/', replace: true, })
        }
      },
    },
    { path: '/unverified_user', component: UnverifiedUser },
    { path: '/send_password_reset_link', component: SendPasswordResetLink },
    {
      path: '/change_password',
      component: ChangePassword,
      props: route => ({
        'email': route.query.email,
        'verificationUrl': route.query.verificationUrl,
      }),
      beforeEnter: (to, from, next) => {
        if (to.query.email && to.query.verificationUrl) {
          next();
        } else {
          next({ path: '/', replace: true, })
        }
      },
    },
  ]

  const router = createVueRouter({
    history: createWebHistory(),
    routes,
  })

  const pathsAllowedForUnauthenticatedUser = [
    '/change_password',
    '/failed_verification',
    '/login',
    '/send_password_reset_link',
    '/sign_up',
    '/successful_verification',
  ]

  const pathsAllowedForAdminOnly = [
    '/users',
  ]

  router.beforeEach((to) => {
    const allowedForUnauthenticatedUser =
      pathsAllowedForUnauthenticatedUser.some(v => (v === to.path))

    if (allowedForUnauthenticatedUser) {
      return true
    }
    if (auth.isBlockedUser()) {
      return to.path === '/blocked_account' ? true : '/blocked_account'
    }
    if (to.path === '/blocked_account') {
      return '/'
    }
    if (auth.isAdmin()) {
      return true
    }
    const allowedForAdminOnly = pathsAllowedForAdminOnly.some(v => (v === to.path))
    if (allowedForAdminOnly) {
      return '/'
    }
    if (auth.isVerifiedUser()) {
      if (to.matched.length === 0) {
        return '/'
      }
      return true
    }
    if (auth.isUser()) {
      if (to.path !== '/unverified_user') {
        return '/unverified_user'
      }
      return true
    }
    return '/login'
  })

  return router
}
