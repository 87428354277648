<template>
  <div class="row justify-content-center h-100 w-100">
    <div class="col-md-7 col-lg-7 col-xl-5 d-flex align-items-center">
      <img
        src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
        class="img-fluid"
        alt="Phone image"
      >
    </div>
    <div class="col-md-5 col-lg-4 col-xl-3">
      <form @submit.prevent="onLogin">
        <div class="mb-2">
          <h3 class="text-center">Resetowanie hasła</h3>
          <p class="text-center">
            Aby zresetować hasło do swojego konta, podaj adres email, którego używasz do logowania
            w systemie.
          </p>
        </div>

        <!-- Email input -->
        <span class="text-danger" v-if="emailWarning">
          <BIconExclamationTriangle /> {{ emailWarning }}
        </span>
        <div class="form-floating mb-2">
          <input
            type="email"
            id="sendPasswordResetLinkFormEmail"
            class="form-control"
            placeholder="Email"
            v-model="email"
            @input="onEmailInput"
          />
          <label class="form-label" for="sendPasswordResetLinkFormEmail">Email</label>
          <div class="d-flex justify-content-end">
            <router-link :to="'/login'">Powrót do logowania</router-link>
          </div>
        </div>

        <!-- Submit button -->
        <div class="d-flex justify-content-center mt-4">
          <button type="submit" class="btn btn-primary btn-lg">Zresetuj hasło</button>
        </div>
      </form>
    </div>
    <modal-template
      header="Wysyłanie linku resetowania hasła"
      id="sendPasswordResetLink__modal"
      ref="sendPasswordResetLink__modal"
    >
      <template v-slot:body>
        <div
          class="row justify-content-center"
          v-if="sendPasswordResetLinkState === 'in_progress'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
        <div
          class="row justify-content-center"
          v-if="sendPasswordResetLinkState === 'success'"
        >
          <b-icon-check-circle-fill class="text-success fs-1 me-2" />
          <p class="text-center">
            Jeżeli podany adres email jest przypisany do jakiegoś użytkownika w systemie,
            została na niego wysłana wiadomość zawierająca link do strony, na której można
            zresetować hasło do konta. Sprawdź swoją skrzynkę odbiorczą i postępuj zgodnie ze
            wskazówkami zawartymi w otrzymanej wiadomości.
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-if="sendPasswordResetLinkState === 'failed'"
        >
          <b-icon-x-circle-fill class="text-danger fs-1 me-2" />
          <p class="text-center">
            Wysyłanie linku nie powiodło się, ponieważ <b>{{ failureReason }}</b>.
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <return-button
          v-if="sendPasswordResetLinkState !== 'in_progress'"
          @click="onSendPasswordResetLinkModalButtonClicked"
        />
      </template>
    </modal-template>
  </div>
</template>

<script>
import {
  BIconCheckCircleFill,
  BIconExclamationTriangle,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import {
  RouterLink,
} from 'vue-router'

import ModalTemplate from '../components/ModalTemplate.vue'
import ReturnButton from '../components/ReturnButton.vue'

function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export default {
  name: 'SendPasswordResetLink',
  components: {
    BIconCheckCircleFill,
    BIconExclamationTriangle,
    BIconXCircleFill,
    ModalTemplate,
    ReturnButton,
    RouterLink,
  },
  data() {
    return {
      email: '',
      emailWarning: '',
      failureReason: '',
      sendPasswordResetLinkState: 'in_progress',
    }
  },
  methods: {
    onLogin() {
      let formValid = true;
      if (!this.email) {
        formValid = false
        this.emailWarning = 'Adres email nie może być pusty'
      } else if(!validateEmail(this.email)) {
        formValid = false
        this.emailWarning = 'Niepoprawny adres email'
      }
      if (formValid) {
        this.sendPasswordResetLinkState = 'in_progress'
        this.$refs['sendPasswordResetLink__modal'].show()
        this.$http.post(
          '/api/send_password_reset_email',
          {
            email: this.email,
          }
        ).then(() => {
          this.sendPasswordResetLinkState = 'success'
        }).catch(error => {
          if (error.response) {
            switch (error.response.status) {
              case 401: // unauthorized
                this.$refs['sendPasswordResetLink__modal'].hide()
                return
              default:
                this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
                break
            }
          } else {
            this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
          }
          this.sendPasswordResetLinkState = 'failed'
        })
      }
    },
    onEmailInput() {
      this.emailWarning = ''
    },
    onSendPasswordResetLinkModalButtonClicked() {
      this.$refs['sendPasswordResetLink__modal'].hide()
      this.$router.replace('/login')
    },
  },
}
</script>
