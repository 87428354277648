<template>
  <nav aria-label="Pagination" v-if="pagesCount > 1">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="currentPage === 1 ? 'disabled' : ''">
        <button
          :aria-disabled="currentPage === 1"
          aria-label="Previous"
          class="page-link"
          @click="$emit('onPageChosen', currentPage - 1)"
        >
          <span aria-hidden="true">&laquo;</span>
        </button>
      </li>
      <li class="page-item" :class="currentPage === 1 ? 'active' : ''">
        <button
          :aria-current="currentPage === 1 ? 'page' : null"
          class="page-link"
          @click="$emit('onPageChosen', 1)"
        >
          1
        </button>
      </li>
      <span class="mx-2" v-if="currentPage > 2">...</span>
      <li class="page-item active" v-if="currentPage !== 1 && currentPage !== pagesCount">
        <button
          aria-current="page"
          class="page-link"
        >
          {{ currentPage }}
        </button>
      </li>
      <span class="mx-2" v-if="currentPage < pagesCount - 1">...</span>
      <li class="page-item" :class="currentPage === pagesCount ? 'active' : ''">
        <button
          :aria-current="currentPage === pagesCount ? 'page' : null"
          class="page-link"
          @click="$emit('onPageChosen', pagesCount)"
        >
          {{ pagesCount }}
        </button>
      </li>
      <li class="page-item" :class="currentPage === pagesCount ? 'disabled' : ''">
        <button
          :aria-disabled="currentPage === pagesCount"
          aria-label="Next"
          class="page-link"
          @click="$emit('onPageChosen', currentPage + 1)"
        >
          <span aria-hidden="true">&raquo;</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'Pagination',
  components: {
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    pagesCount: {
      type: Number,
      required: true,
    },
  },
  emits: [
    'onPageChosen',
  ],
  computed: {
  },
  methods: {
  },
};
</script>
