import 'bootstrap/dist/css/bootstrap.min.css'
import bootstrap from 'bootstrap/dist/js/bootstrap'

import { createApp } from 'vue'
import { createAxios } from './libs/axios'
import { createRouter } from './libs/router'
import { store } from './store'
import App from './App.vue'
import VueAxios from 'vue-axios'

const app = createApp(App)

const router = createRouter()
app.use(router)
app.use(VueAxios, createAxios(router, store))
app.use(store)
app.config.globalProperties.$store = store
app.config.globalProperties.$bootstrap = bootstrap

app.mount('#app')
