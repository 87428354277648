<template>
  <div class="d-flex flex-column h-100">
    <div class="flex-grow-0 flex-shrink-1 mb-1">
      <div class="row g-1">
        <div class="col-12 col-md">
          <form class="input-group">
            <span class="input-group-text" id="firstNameFilterLabel">
              <b-icon-search class="me-1"/>Imię
            </span>
            <input
              aria-describedby="firstNameFilterLabel"
              aria-label="firstNameFilter"
              class="form-control"
              type="text"
              v-model="firstNameFilter"
              @input="onFilterInput"
            >
          </form>
        </div>
        <div class="col-12 col-md">
          <form class="input-group">
            <span class="input-group-text" id="lastNameFilterLabel">
              <b-icon-search class="me-1"/>Nazwisko
            </span>
            <input
              aria-describedby="lastNameFilterLabel"
              aria-label="firstNameFilter"
              class="form-control"
              type="text"
              v-model="lastNameFilter"
              @input="onFilterInput"
            >
          </form>
        </div>
      </div>
    </div>
    <div class="flex-grow-1 flex-shrink-1 overflow-auto mb-1" >
      <table class="table table-striped align-middle text-center border">
        <thead class="table-info">
          <tr>
            <th>Akcje</th>
            <th>Imię</th>
            <th>Nazwisko</th>
            <th>Email</th>
            <th>Tel.</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="requestingData">
            <td colspan="6">
              <div class="spinner-border text-info" role="status" />
            </td>
          </tr>
          <tr v-else-if="failedToRequestData">
            <td colspan="6">
              <b-icon-emoji-frown class="text-danger fs-2" /><br />Błąd pobierania danych.
            </td>
          </tr>
          <tr v-else-if="users.length === 0">
            <td colspan="6">
              Brak wyników
            </td>
          </tr>
          <tr v-else v-for="(user, i) in users" :key="i">
            <td>
              <div class="dropdown">
                <button
                  aria-expanded="false"
                  :id="`userActionDropdownMenuButton_${i}`"
                  type="button"
                  class="btn btn-secondary dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  <b-icon-pencil-square/>
                </button>
                <ul class="dropdown-menu overflow-visible" :aria-labelledby="`userActionDropdownMenuButton_${i}`">
                  <li>
                    <button
                      class="dropdown-item"
                      v-if="!isBlocked(user) && isVerified(user) && !isAdmin(user)"
                      @click="onUserActionClicked(i, 'add_admin_role')"
                    >
                      Nadaj uprawnienia Administratora
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      v-if="isAdmin(user)"
                      @click="onUserActionClicked(i, 'remove_admin_role')"
                    >
                      Odbierz uprawnienia Administratora
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      v-if="!isBlocked(user) && !isVerified(user)"
                      @click="onUserActionClicked(i, 'verify_user')"
                    >
                      Zweryfikuj
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      v-if="!isBlocked(user)"
                      @click="onUserActionClicked(i, 'block_user')"
                    >
                      Zablokuj
                    </button>
                  </li>
                  <li>
                    <button
                      class="dropdown-item"
                      v-if="isBlocked(user)"
                      @click="onUserActionClicked(i, 'unblock_user')"
                    >
                      Odblokuj
                    </button>
                  </li>
                </ul>
              </div>
            </td>
            <td>
              {{ user.firstName }}
            </td>
            <td>
              {{ user.lastName }}
            </td>
            <td>
              <a :href="`mailto:${user.email}`">{{ user.email }}</a>
            </td>
            <td>
              <a :href="`tel:+48${user.phoneNumber}`" v-if="user.phoneNumber">
                {{ user.phoneNumber }}
              </a>
            </td>
            <td>
              {{ status(user) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="flex-grow-0 flex-shrink-1 align-items-bottom">
      <pagination
        :currentPage="currentPage"
        :pagesCount="pagesCount"
        @onPageChosen="onPageChosen"
      />
    </div>
    <modal-template
      header="Zmiana statusu użytkownika"
      id="users__action__modal"
      ref="users__action__modal"
    >
      <template v-slot:body>
        <div
          class="row justify-content-center"
          v-if="changeUserStatus.state === 'in_progress'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
        <div
          class="row justify-content-center"
          v-else-if="changeUserStatus.state === 'succeeded'"
        >
          <b-icon-check-circle-fill class="text-success fs-1 me-2" />
          <p class="text-center">
            Pomyślnie zaktualizowano status użytkownika.
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-else-if="changeUserStatus.state === 'failed'"
        >
          <b-icon-x-circle-fill class="text-danger fs-1 me-2" />
          <p class="text-center">
            Nie udało się zaktualizować statusu użytkownika, ponieważ <b>{{ failureReason }}</b>.
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-else
        >
          <p class="text-center">
            Czy na pewno {{ userChangeStatusText }}
            <b>{{ `${users[changeUserStatus.userIndex].firstName} ${users[changeUserStatus.userIndex].lastName}` }}</b>
            (email: <b>{{ users[changeUserStatus.userIndex].email }}</b>)?
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <return-button v-if="changeUserStatus.state !== 'in_progress'" @click="hideUserActionModal" />
        <button
          type="button"
          class="btn btn-primary"
          v-if="changeUserStatus.state !== 'in_progress' &&
                changeUserStatus.state !== 'succeeded' &&
                changeUserStatus.state !== 'failed'"
          @click="updateUserRoles"
        >
          Tak
        </button>
      </template>
    </modal-template>
  </div>
</template>

<script>
import {
  BIconCheckCircleFill,
  BIconEmojiFrown,
  BIconPencilSquare,
  BIconSearch,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import ModalTemplate from '../components/ModalTemplate.vue'
import Pagination from '../components/Pagination.vue'
import ReturnButton from '../components/ReturnButton.vue'

export default {
  name: 'Users',
  components: {
    BIconCheckCircleFill,
    BIconEmojiFrown,
    BIconPencilSquare,
    BIconSearch,
    BIconXCircleFill,
    ModalTemplate,
    Pagination,
    ReturnButton,
  },
  props: {
  },
  computed: {
    userChangeStatusText() {
      if (this.changeUserStatus.state === 'confirmingAddAdminRole') {
        return 'nadać uprawnienia Administratora użytkownikowi'
      }
      if (this.changeUserStatus.state === 'confirmingRemoveAdminRole') {
        return 'odebrać uprawnienia Administratora użytkownikowi'
      }
      if (this.changeUserStatus.state === 'confirmingBlockUser') {
        return 'zablokować dostęp użytkownikowi'
      }
      if (this.changeUserStatus.state === 'confirmingUnblockUser') {
        return 'odblokować dostęp użytkownikowi'
      }
      if (this.changeUserStatus.state === 'confirmingVerifyUser') {
        return 'zweryfikować użytkownika'
      }
      return ''
    }
  },
  beforeUnmount() {
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout)
      this.typingTimeout = null
    }
  },
  created() {
    this.getUsers()
  },
  data() {
    return {
      changeUserStatus: {
        state: 'succeeded',
        userIndex: -1,
      },
      currentPage: 1,
      failedToRequestData: false,
      firstNameFilter: '',
      lastNameFilter: '',
      pagesCount: 1,
      requestingData: false,
      typingTimeout: null,
      users: [],
    }
  },
  methods: {
    getUsers() {
      this.requestingData = true
      this.failedToRequestData = false
      const params = {
        page: this.currentPage,
        'order[firstName]': 'asc',
        'order[lastName]': 'asc',
      }
      if (this.firstNameFilter) {
        params.firstName = this.firstNameFilter
      }
      if (this.lastNameFilter) {
        params.lastName = this.lastNameFilter
      }
      this.$http.get(
        '/api/users',
        {
          headers: {
            accept: 'application/ld+json'
          },
          params,
        })
        .then(response => {
          if (response.data) {
            this.users = response.data['hydra:member']
            let pagesCount = 0
            const hydraView = response.data['hydra:view']
            if (hydraView) {
              const hydraViewLast = hydraView['hydra:last']
              if (hydraViewLast) {
                const pageParam = /(\?|&)page=([0-9]+)/.exec(hydraViewLast)
                if (pageParam) {
                  pagesCount = parseInt(pageParam[2])
                }
              }
            }
            this.pagesCount = pagesCount
          }
          this.requestingData = false
        })
        .catch(() => {
          this.requestingData = false
          this.failedToRequestData = true
        })
    },
    hideUserActionModal() {
      this.$refs['users__action__modal'].hide()
    },
    isAdmin(user) {
      return user.roles.some(v => (v === 'ROLE_ADMIN'))
    },
    isBlocked(user) {
      return user.roles.some(v => (v === 'ROLE_BLOCKED_USER'))
    },
    isVerified(user) {
      return user.roles.some(v => (v === 'ROLE_VERIFIED_USER'))
    },
    onUserActionClicked(userIndex, action) {
      this.changeUserStatus.userIndex = userIndex
      if (action === 'add_admin_role') {
        this.changeUserStatus.state = 'confirmingAddAdminRole'
      } else if (action === 'remove_admin_role') {
        this.changeUserStatus.state = 'confirmingRemoveAdminRole'
      } else if (action === 'block_user') {
        this.changeUserStatus.state = 'confirmingBlockUser'
      } else if (action === 'unblock_user') {
        this.changeUserStatus.state = 'confirmingUnblockUser'
      } else {
        this.changeUserStatus.state = 'confirmingVerifyUser'
      }
      this.showUserActionModal()
    },
    onFilterInput() {
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout)
      }
      this.typingTimeout = setTimeout(
        () => {
          this.typingTimeout = null
          this.currentPage = 1
          this.getUsers()
        },
        500)
    },
    onPageChosen(page) {
      this.currentPage = page
      this.getUsers()
    },
    showUserActionModal() {
      this.$refs['users__action__modal'].show()
    },
    status(user) {
      if (this.isBlocked(user)) {
        return 'Zablokowany'
      }
      if (!this.isVerified(user)) {
        return 'Niezweryfikowany'
      }
      if (!this.isAdmin(user)) {
        return 'Użytkownik'
      }
      return 'Administrator'
    },
    updateUserRoles() {
      const params = {}
      const user = this.users[this.changeUserStatus.userIndex]
      params.roles = [...user.roles]
      if (this.changeUserStatus.state === 'confirmingAddAdminRole') {
        params.roles.push('ROLE_ADMIN')
      } else if (this.changeUserStatus.state === 'confirmingRemoveAdminRole') {
        params.roles = params.roles.filter(v => (v !== 'ROLE_ADMIN'))
      } else if (this.changeUserStatus.state === 'confirmingBlockUser') {
        params.roles.push('ROLE_BLOCKED_USER')
      } else if (this.changeUserStatus.state === 'confirmingUnblockUser') {
        params.roles = params.roles.filter(v => (v !== 'ROLE_BLOCKED_USER'))
      } else if (this.changeUserStatus.state === 'confirmingVerifyUser') {
        params.roles.push('ROLE_VERIFIED_USER')
      } else {
        return
      }

      this.changeUserStatus.state = 'in_progress'
      this.$http.put(`/api/users/${user.id}`, params)
        .then(response => {
          if (response.data) {
            this.changeUserStatus.state = 'succeeded'
            this.users[this.changeUserStatus.userIndex] = response.data
            return
          }

          this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
          this.changeUserStatus.state = 'failed'
        }).catch(error => {
          if (error.response) {
            switch (error.response.status) {
              case 401: // unauthorized
                this.hideUserActionModal()
                return
              default:
                this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
                break
            }
          } else {
            this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
          }
          this.changeUserStatus.state = 'failed'
        })
    }
  },
}
</script>
