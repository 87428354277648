<template>
  <div class="d-flex">
    <div class="form-floating flex-grow-1">
      <input
        class="form-control"
        :id="id"
        :placeholder="label"
        :type="showPassword ? 'text' : 'password'"
        :value="modelValue"
        @input="onInput"
      />
      <label class="form-label" :for="id">{{ label }}</label>
    </div>
    <button
      :class="`btn border btn-${showPassword ? 'secondary' : 'light'}`"
      type="button"
      @click="onShow"
    >
      <b-icon-eye-slash-fill v-if="showPassword"/>
      <b-icon-eye-fill v-else/>
    </button>
  </div>
</template>

<script>
import {
  BIconEyeFill,
  BIconEyeSlashFill,
} from 'bootstrap-icons-vue'

export default {
  name: 'PasswordInput',
  components: {
    BIconEyeFill,
    BIconEyeSlashFill,
  },
  data() {
    return {
      showPassword: false,
    }
  },
  emits: [
    'update:modelValue'
  ],
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      required: true,
    }
  },
  methods: {
    onInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
    onShow() {
      this.showPassword = !this.showPassword
    },
  },
};
</script>
