<template>
  <modal-template header="Szczegóły rezerwacji" :id="id" :ref="id">
    <template v-slot:body>
      <div class="text-center">
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="fs-5"><u>Rezerwacja</u></div>
            <div>Obiekt: <b>{{ facilityName }}</b></div>
            <div>Dzień: <b>{{ printableDate }}</b></div>
            <div>Godziny: <b>{{ startHour }} - {{ endHour }}</b></div>
            <div v-if="reservation?.reservedBy">
              Dokonana przez:
              <b>{{ reservation?.reservedBy.firstName }} {{ reservation.reservedBy.lastName }}</b>
            </div>
          </li>
          <li class="list-group-item">
            <div class="fs-5"><u>Użytkownik</u></div>
            <div>Imię: <b>{{ reservation?.owner?.firstName }}</b></div>
            <div>Nazwisko: <b>{{ reservation?.owner?.lastName }}</b></div>
            <div>Email:
              <a :href="`mailto:${reservation?.owner?.email}`">{{ reservation?.owner?.email }}</a>
            </div>
            <div v-if="reservation?.owner?.phoneNumber">
              Tel.:
              <a :href="`tel:+48${reservation.owner.phoneNumber}`">
                {{ `+48 ${reservation.owner.phoneNumber}` }}
              </a>
            </div>
          </li>
          <li class="list-group-item" v-if="isAdmin">
            <div class="fs-5"><u>Dodatkowe informacje:</u></div>
            <div>
              <textarea
                class="form-control"
                maxlength="127"
                v-model="additionalInfoInput"
              />
            </div>
          </li>
        </ul>
      </div>
    </template>
    <template v-slot:footer>
      <return-button data-bs-dismiss="modal" />
      <button
        type="button"
        class="btn btn-primary"
        :class="{ disabled: !isAdditionalInfoModified }"
        @click="onEditClicked"
      >
        <div class="d-flex align-items-center">
          <span class="me-1">Edytuj</span><b-icon-pencil-square/>
        </div>
      </button>
      <button
        v-if="enableCancelation"
        type="button"
        class="btn btn-danger"
        @click="onCancelReservationClicked"
      >
        <div class="d-flex align-items-center">
          <span class="me-1">Odwołaj</span><b-icon-calendar-2-x-fill/>
        </div>
      </button>
    </template>
  </modal-template>
</template>

<script>
import {
  BIconCalendar2XFill,
  BIconPencilSquare,
} from 'bootstrap-icons-vue'

import utils from '@/libs/utils'

import ModalTemplate from './ModalTemplate.vue'
import ReturnButton from './ReturnButton.vue'

export default {
  name: 'ReservationDetailsModal',
  components: {
    BIconCalendar2XFill,
    BIconPencilSquare,
    ModalTemplate,
    ReturnButton,
  },
  props: {
    reservation: {
      type: Object,
      default: null,
    },
    date: {
      type: Date,
      required: true,
    },
    enableCancelation: {
      type: Boolean,
      required: true,
    },
    facilityName: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    startSlotIdx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      additionalInfoInput: '',
    }
  },
  emits: [
    'cancelReservation',
    'update:additionalInfo'
  ],
  computed: {
    endHour() {
      if (!this.reservation) {
        return null
      }
      return utils.slotIdxToPrintableHour(this.startSlotIdx + this.reservation.rowspan)
    },
    startHour() {
      if (!this.reservation) {
        return null
      }
      return utils.slotIdxToPrintableHour(this.startSlotIdx)
    },
    printableDate() {
      return utils.printableDate(this.date)
    },
    isAdditionalInfoModified() {
      const current = this.reservation?.additionalInfo ? this.reservation.additionalInfo : ''
      return current !== this.additionalInfoInput
    },
    isAdmin() {
      const user = this.$store.state.user
      return user.roles && user.roles.some(v => (v === 'ROLE_ADMIN'))
    },
  },
  watch: {
    reservation(newValue) {
      if (newValue) {
        this.additionalInfoInput = newValue.additionalInfo ? newValue.additionalInfo : ''
      }
    }
  },
  methods: {
    hide() {
      this.$refs[this.id].hide()
    },
    onCancelReservationClicked() {
      this.hide()
      this.$emit('cancelReservation')
    },
    onEditClicked() {
      this.hide()
      this.$emit('update:additionalInfo', this.additionalInfoInput)
    },
    show() {
      this.additionalInfoInput =
        this.reservation?.additionalInfo ? this.reservation.additionalInfo : ''
      this.$refs[this.id].show()
    },
  },
};
</script>
