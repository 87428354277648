import auth from './auth'
import axios from 'axios'

class AxiosService {
  axios = null
  router = null
  store = null

  constructor(router, store) {
    this.router = router
    this.store = store
    this.axios = axios.create({
      baseURL: process.env.NODE_ENV === 'production' ?
          '/backend/' : `${window.location.protocol}//${window.location.hostname}:8081/`,
      headers: {
        accept: 'application/json',
      },
      timeout: 60000
    })

    this.axios.interceptors.request.use(
      config => {
        const accessToken = localStorage.getItem('accessToken')
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    this.axios.interceptors.response.use(
      response => response,
      error => {
        const { request, response } = error

        if (request && request.url !== '/api/login_check' && response && response.status === 401) {
          auth.clearCredentials()
          this.store.commit('user/setUserData', null)
          this.router.push('/login')
        }

        return Promise.reject(error)
      }
    )
  }
}

export function createAxios(router, store) {
  const axiosService = new AxiosService(router, store)

  return axiosService.axios
}
