<template>
  <div class="container h-100 w-100">
    <h1 class="text-center mb-3">
      Weryfikacja zakończona
    </h1>
    <p class="text-center"><b-icon-check-circle-fill class="text-success fs-1" /></p>
    <p class="text-center">
      Użytkownik <b>{{ firstName }} {{ lastName }}</b>, posługujący się adresem email
      <b>{{ email }}</b>, został pomyślnie zweryfikowany.
    </p>
    <p class="text-center">
      Możesz teraz <router-link :to="'login'">zalogować się</router-link> i w pełni korzystać
      z systemu.
    </p>
  </div>
</template>

<script>
import {
  BIconCheckCircleFill,
} from 'bootstrap-icons-vue'

import {
  RouterLink,
} from 'vue-router'

import auth from '../libs/auth'

export default {
  name: 'SuccessfulVerification',
  components: {
    BIconCheckCircleFill,
    RouterLink,
  },
  created() {
    auth.clearCredentials()
    this.$store.commit('user/setUserData', null)
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    firstName: {
      type: String,
      required: true,
    },
    lastName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
    }
  },
}
</script>
