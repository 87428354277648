<template>
  <div class="container h-100 w-100">
    <nav>
      <div class="nav nav-tabs" id="my_reservations_tab" role="tablist">
        <button
          class="nav-link active"
          id="my_future_reservations_tab"
          data-bs-toggle="tab"
          data-bs-target="#my_future_reservations"
          type="button"
          role="tab"
          aria-controls="my_future_reservations"
          aria-selected="true">
            Przyszłe
        </button>
        <button
          class="nav-link"
          id="my_past_reservations_tab"
          data-bs-toggle="tab"
          data-bs-target="#my_past_reservations"
          type="button"
          role="tab"
          aria-controls="my_future_reservations"
          aria-selected="false">
            Minione
        </button>
      </div>
    </nav>
    <div class="tab-content p-1 border-start border-end border-bottom" id="my_reservations_tab_content">
      <div
        class="tab-pane fade overflow-auto show active"
        id="my_future_reservations"
        role="tabpanel"
        aria-labelledby="my_future_reservations_tab"
      >
        <reservation-list :id="'future_reservation_list'" :past="false" />
      </div>
      <div
        class="tab-pane fade overflow-auto"
        id="my_past_reservations"
        role="tabpanel"
        aria-labelledby="my_past_reservations_tab"
      >
        <reservation-list :id="'past_reservation_list'" :past="true" />
      </div>
    </div>
  </div>
</template>

<script>

import ReservationList from '../components/ReservationList.vue'

export default {
  name: 'MyReservations',
  components: {
    ReservationList,
  },
  props: {
  },
  computed: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
}
</script>
