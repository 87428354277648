<template>
  <table
    class="table table-borderless align-middle text-center"
    style="border-collapse: collapse;"
    :id="id"
  >
    <thead>
      <tr>
        <th
          style="position: -web-sticky;
                 position: sticky;
                 left: 0;
                 top: 0;
                 z-index: 1;
                 background-color: white;
                 box-shadow: inset 0 -2px black, inset -2px 0 black;
                 width: 0.1%
                 white-space: nowrap"
        >
          <button
            class="btn btn-primary btn-sm rounded-circle p-1"
            type="button"
            @click="onRefreshButtonClicked"
          >
            <b-icon-arrow-clockwise class="fs-4" />
          </button>
        </th>
        <th
          v-if="facilities.length === 0"
          class="table-primary"
          style="position: -web-sticky;
                 position: sticky;
                 top: 0;
                 box-shadow: inset 0 2px black, inset 0 -2px black, inset -2px 0 black;"
        >
        </th>
        <th
          v-else
          v-for="[i, facility] of facilities.entries()"
          class="table-primary"
          style="position: -web-sticky;
                 position: sticky;
                 top: 0;
                 box-shadow: inset 0 2px black, inset 0 -2px black, inset -2px 0 black;"
          :key="i"
        >
          {{ facility }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(_, i) in Array((22 - 7) * 2)" :key="i">
        <th
          class="table-secondary"
          scope="row"
          style="position: -web-sticky;
                 position: sticky;
                 left: 0;
                 box-shadow: inset 0 -2px black, inset 2px 0 black, inset -2px 0 black;"
        >
          {{ timeColumnText(i) }}
        </th>
        <td
          v-for="[facility, items] of Object.entries(reservationsOfFacilities)
                   .filter(([k, v]) => (v[i].rowspan > 0))"
          style="box-shadow: inset 0 -1px black, inset -1px 0 black;"
          :class="getReservationCellColor(items[i])"
          :key="facility"
          :rowspan="items[i].rowspan"
        >
          <button
            class="btn btn-primary btn-sm"
            type="button"
            v-if="!items[i].reserved && !slotTimePassed(i)"
            @click="onReserveClicked(facility, i)"
          >
            Rezerwuj
          </button>
          <div class="lh-1" v-if="items[i].reserved && items[i].rowspan > 0">
            <span v-html="getReservationText(items[i])" />
            <div class="pt-2">
              <button
                class="btn btn-secondary btn-sm"
                type="button"
                v-if="shouldShowReservationInfoButton(items[i])"
                @click="onReservationInfoClicked(facility, i)"
              >
                <div class="d-flex align-items-center">
                  <span class="me-1">Pokaż</span><b-icon-info-circle/>
                </div>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
  <div
    class="spinner-border text-info position-absolute top-50 start-50"
    role="status"
    v-if="Object.keys(reservationsOfFacilities).length === 0 && !failedToRequestData"
  />
  <div
    class="text-center position-absolute top-50 start-50"
    v-if="Object.keys(reservationsOfFacilities).length === 0 && failedToRequestData"
  >
    <b-icon-emoji-frown class="text-danger fs-2" /><br /><br />Błąd<br />pobierania<br />danych.
  </div>
  <reserving-modal
    :availableSubsequentSlotsCount="makeReservationInfo.availableSubsequentSlots"
    :date="date"
    :facilityName="makeReservationInfo.facilityName"
    :id="`${id}__reservingModal`"
    :ref="`${id}__reservingModal`"
    :startSlotIdx="makeReservationInfo.reservationStartSlotIdx"
    @failure="onUnsuccessfulReservation"
    @success="onSuccessfulReservation"
  />
  <canceling-modal
    :date="date"
    :reservation="chosenReservation"
    :facilityName="chosenFacility"
    :id="`${id}__cancelingModal`"
    :ref="`${id}__cancelingModal`"
    :startSlotIdx="chosenReservationStartSlotIdx"
    @failure="onUnsuccessfulCancelation"
    @success="onSuccessfulCancelation"
  />
  <editing-modal
    :date="date"
    :reservation="chosenReservation"
    :facilityName="chosenFacility"
    :id="`${id}__editingModal`"
    :ref="`${id}__editingModal`"
    :startSlotIdx="chosenReservationStartSlotIdx"
    :newAdditionalInfo="newAdditionalInfo"
    @failure="onUnsuccessfulEdit"
    @success="onSuccessfulEdit"
  />
  <reservation-details-modal
    :date="date"
    :reservation="chosenReservation"
    :enableCancelation="!chosenReservationSlotTimePassed"
    :facilityName="chosenFacility"
    :id="`${id}__reservationDetailsModal`"
    :ref="`${id}__reservationDetailsModal`"
    :startSlotIdx="chosenReservationStartSlotIdx"
    @cancelReservation="onCancelClicked"
    @update:additional-info="onEditClicked"
  />
  <success-toast
    message="Rezerwacja przebiegła pomyślnie"
    :id="`${id}__reservationSuccessToast`"
    :ref="`${id}__reservationSuccessToast`"
  />
  <success-toast
    message="Pomyśnie odwołano rezerwację"
    :id="`${id}__cancelationSuccessToast`"
    :ref="`${id}__cancelationSuccessToast`"
  />
  <success-toast
    message="Edycja rezerwacji przebiegła pomyślnie"
    :id="`${id}__editSuccessToast`"
    :ref="`${id}__editSuccessToast`"
  />
</template>

<script>
import {
  BIconArrowClockwise,
  BIconEmojiFrown,
  BIconInfoCircle,
} from 'bootstrap-icons-vue'

import CancelingModal from './CancelingModal.vue'
import EditingModal from './EditingModal.vue'
import ReservationDetailsModal from './ReservationDetailsModal.vue'
import ReservingModal from './ReservingModal.vue'
import SuccessToast from './SuccessToast.vue'

export default {
  name: 'Schedule',
  components: {
    BIconArrowClockwise,
    BIconEmojiFrown,
    BIconInfoCircle,
    CancelingModal,
    EditingModal,
    ReservationDetailsModal,
    ReservingModal,
    SuccessToast,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
  },
  data() {
    return {
      facilities: [],
      makeReservationInfo: {
        facilityName: '',
        reservationStartSlotIdx: 0,
        availableSubsequentSlots: 0,
      },
      newAdditionalInfo: '',
      chosenReservation: null,
      chosenFacility: null,
      chosenReservationStartSlotIdx: null,
      reservationsOfFacilities: {},
      serverTime: null,
      serverTimezoneDiff: null,
      failedToRequestData: false,
    }
  },
  computed: {
    chosenReservationSlotTimePassed() {
      return this.chosenReservationStartSlotIdx ?
        this.slotTimePassed(this.chosenReservationStartSlotIdx) : false
    },
  },
  created() {
    this.getData()
  },
  watch: {
    date() {
      this.getServerTimeAndReservations()
    },
  },
  methods: {
    timeColumnText(index) {
      const hour = index / 2 + 7
      return (hour < 10 ? '0' : '') + Math.floor(hour) + ':' + (index % 2 ? '30' : '00')
    },
    getData() {
      this.failedToRequestData = false
      this.$http.get('/api/facilities')
        .then(response => {
          if (response.data) {
            const facilities = response.data.map(v => (v.name));
            this.facilities = facilities
            this.getServerTimeAndReservations()
          }
        })
        .catch(error => {
          this.handleResponseError(error)
        })
    },
    getReservations() {
      const endDate = new Date(this.date)
      endDate.setDate(this.date.getDate() + 1)
      this.$http.get(
        '/api/reservations',
        {
          params: {
            'startTime[after]':
                `${this.date.getFullYear()}-${this.date.getMonth() + 1}-${this.date.getDate()}`,
            'startTime[strictly_before]':
                `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`,
            pagination: false,
          }
        })
        .then(response => {
          if (response.data) {
            const reservationsOfFacilities = Object.fromEntries(
              this.facilities.map(v => ([v, new Array((22 -7) * 2)])))
            for (const facilityReservations of Object.entries(reservationsOfFacilities)) {
              for (let i = 0; i < facilityReservations.length; ++i) {
                facilityReservations[i] = { reserved: false, rowspan: 1 }
              }
            }
            for (const facility of this.facilities) {
              const items = Array((22 -7) * 2)
              for (let i = 0; i < items.length; ++i) {
                items[i] = { reserved: false, rowspan: 1 }
              }
              reservationsOfFacilities[facility] = items;
            }

            for (const res of response.data) {
              const startTime = new Date(res.startTime)
              const firstSlotIdx =
                (startTime.getHours() - 7) * 2 + (startTime.getMinutes() >= 30 ? 1 : 0)
              const endTime = new Date(res.endTime)
              const lastSlotIdx =
                (endTime.getHours() - 7) * 2 + (endTime.getMinutes() >= 30 ? 1 : 0) - 1
              const facilityName = decodeURIComponent(res.facility.split('/').pop())
              const facility = reservationsOfFacilities[facilityName]
              if (firstSlotIdx >= 0 && firstSlotIdx < facility.length) {
                const item = facility[firstSlotIdx]
                item.reserved = true
                item.rowspan = lastSlotIdx - firstSlotIdx + 1
                item.owner = res.owner
                item.reservationId = res.id
                item.additionalInfo = res.additionalInfo
                item.reservedBy = res.reservedBy

                for (let i = lastSlotIdx; i >= 0 && i < facility.length && i > firstSlotIdx; --i) {
                  const item_ = facility[i]
                  item_.reserved = true
                  item_.rowspan = 0
                }
              }
            }
            this.reservationsOfFacilities = reservationsOfFacilities
          }
        })
        .catch(error => {
          this.handleResponseError(error)
        })
    },
    getServerTimeAndReservations() {
      this.failedToRequestData = false
      this.reservationsOfFacilities = {}
      this.$http.get('/api/time_now')
        .then(response => {
          if (response.data) {
            const timeNow = response.data.timeNow
            if (timeNow) {
              this.serverTime = new Date(timeNow)
              this.serverTimezoneDiff = /((\+|-)[0-9]{2}:[0-9]{2})$/.exec(timeNow)[1]
              this.getReservations()
            }
          }
        })
        .catch(error => {
          this.handleResponseError(error)
        })
    },
    getReservationText(reservation) {
      if (!reservation.reserved) {
        return 'Wolne'
      }
      if (!reservation.owner) {
        return 'Rezerwacja'
      }
      if (this.isCurrentUserId(reservation.owner.id)) {
        return 'Twoja rezerwacja'
      }
      return `Rezerwacja<br /><small>${reservation.owner.firstName} ${reservation.owner.lastName}</small>`
    },
    getReservationCellColor(reservation) {
      if (!reservation.reserved || reservation.rowspan === 0) {
        return 'table-success'
      }
      return reservation.owner && this.isCurrentUserId(reservation.owner.id) ? 'table-info'
                                                                             : 'table-danger'
    },
    handleResponseError(error) {
      if (error.response && error.response.status === 401) {
        this.$refs[`${this.id}__cancelingModal`].hide()
        this.$refs[`${this.id}__reservingModal`].hide()
      } else {
        this.failedToRequestData = true
      }
    },
    onCancelClicked() {
      this.$refs[`${this.id}__cancelingModal`].show()
    },
    onEditClicked(newAdditionalInfo) {
      this.newAdditionalInfo = newAdditionalInfo
      this.$refs[`${this.id}__editingModal`].show()
    },
    onRefreshButtonClicked() {
      if (this.facilities.length > 0) {
        this.getServerTimeAndReservations()
      } else {
        this.getData()
      }
    },
    onReservationInfoClicked(facility, reservationStartSlotIdx) {
      this.chosenFacility = facility
      this.chosenReservationStartSlotIdx = reservationStartSlotIdx
      this.chosenReservation = this.reservationsOfFacilities[facility][reservationStartSlotIdx]
      this.$refs[`${this.id}__reservationDetailsModal`].show()
    },
    onReserveClicked(facility, reservationStartSlotIdx) {
      const items = this.reservationsOfFacilities[facility]
      let i = reservationStartSlotIdx + 1;
      while (i < items.length && !items[i].reserved) {
        ++i;
      }
      this.makeReservationInfo = {
        facilityName: facility,
        reservationStartSlotIdx: reservationStartSlotIdx,
        availableSubsequentSlots: i - reservationStartSlotIdx - 1,
      }
      this.$refs[`${this.id}__reservingModal`].show()
    },
    onSuccessfulCancelation() {
      this.getServerTimeAndReservations()
      this.$refs[`${this.id}__cancelationSuccessToast`].show()
    },
    onSuccessfulEdit(newAdditionalInfo) {
      this.chosenReservation.additionalInfo = newAdditionalInfo
      this.$refs[`${this.id}__editSuccessToast`].show()
    },
    onSuccessfulReservation() {
      this.getServerTimeAndReservations()
      this.$refs[`${this.id}__reservationSuccessToast`].show()
    },
    onUnsuccessfulCancelation() {
      this.getServerTimeAndReservations()
    },
    onUnsuccessfulEdit() {
      this.getServerTimeAndReservations()
    },
    onUnsuccessfulReservation() {
      this.getServerTimeAndReservations()
    },
    shouldShowReservationInfoButton(reservation) {
      return reservation.owner
    },
    slotTimePassed(slotNumber) {
      const year = this.date.getFullYear()
      const month = this.date.getMonth() + 1
      const monthStr = `${month < 10 ? '0' : ''}${month}`
      const date = this.date.getDate()
      const dateStr = `${date < 10 ? '0' : ''}${date}`
      const hour = Math.floor(slotNumber / 2) + 7
      const hourStr = `${hour < 10 ? '0' : ''}${hour}`
      const minuteStr = slotNumber % 2 === 0 ? '00' : '30'
      const slotStartTime = new Date(`${year}-${monthStr}-${dateStr}T${hourStr}:${minuteStr}${this.serverTimezoneDiff}`)
      return slotStartTime < this.serverTime
    },
    isCurrentUserId(id) {
      return this.$store.state.user.id === id
    },
  },
};
</script>
