<template>
  <modal-template header="Odwoływanie rezerwacji" :id="id" :ref="id">
    <template v-slot:body>
      <div class="text-center" v-if="state === 'confirming' || state === 'processing'">
        <span>
          Czy na pewno chcesz odwołać rezerwację obiektu <b>{{ facilityName }}</b>, na dzień
          <b>{{ printableDate }}</b>, w godzinach <b>{{ startHour }} - {{ endHour }}</b>
        </span>
        <span v-if="shouldShowOwner">
          , użytkownika <b>{{ ownerName }}</b>
          (<a :href="`mailto:${reservation?.owner?.email}`">{{ reservation?.owner?.email }}</a>)
        </span>
        <span>?</span>
        <div
          class="row justify-content-center"
          v-if="state === 'processing'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
      </div>
      <div class="text-center" v-else>
        <p>
          <b-icon-exclamation-triangle-fill
            class="text-warning fs-1"
            v-if="state === 'failedNotFound'"
          />
          <b-icon-x-circle-fill class="text-danger fs-1" v-else />
        </p>
        <p v-if="state === 'failedNotFound'">
          Ta rezerwacja nie istnieje w systemie. Najprawdopodobnie została już odwołana.
        </p>
        <p v-else-if="state === 'failedForOtherReason'">
          Odwołanie rezerwacji nie powiodło się ponieważ wystąpił wewnętrzny błąd w systemie.
        </p>
        <p v-else-if="state === 'failedDueToResponseTimeout'">
          Odwołanie rezerwacji nie powiodło się ponieważ serwer nie odpowiedział w wyznaczonym
          czasie.
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        v-show="state === 'confirming'"
      >
        Nie
      </button>
      <return-button v-if="state !== 'confirming' && state !== 'processing'" />
      <button
        type="button"
        class="btn btn-danger"
        v-if="state === 'confirming'"
        @click="cancelReservation"
      >
        <div class="d-flex align-items-center">
          <span class="me-1">Odwołaj</span><b-icon-calendar-2-x-fill/>
        </div>
      </button>
    </template>
  </modal-template>
</template>

<script>
import {
  BIconCalendar2XFill,
  BIconExclamationTriangleFill,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import utils from '@/libs/utils'

import ModalTemplate from './ModalTemplate.vue'
import ReturnButton from './ReturnButton.vue'

export default {
  name: 'ReservingModal',
  components: {
    BIconCalendar2XFill,
    BIconExclamationTriangleFill,
    BIconXCircleFill,
    ModalTemplate,
    ReturnButton,
  },
  props: {
    reservation: {
      type: Object,
      default: null,
    },
    date: {
      type: Date,
      required: true,
    },
    facilityName: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      required: true,
    },
    startSlotIdx: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      state: 'confirming',
    }
  },
  emits: [
    'success',
    'failure',
  ],
  computed: {
    endHour() {
      if (!this.startSlotIdx || !this.reservation) {
        return null
      }
      return utils.slotIdxToPrintableHour(this.startSlotIdx + this.reservation.rowspan)
    },
    startHour() {
      if (!this.startSlotIdx) {
        return null
      }
      return utils.slotIdxToPrintableHour(this.startSlotIdx)
    },
    printableDate() {
      if (!this.date) {
        return null
      }
      return utils.printableDate(this.date)
    },
    ownerName() {
      return this.reservation?.owner?.firstName && this.reservation?.owner?.lastName ?
        `${this.reservation.owner.firstName} ${this.reservation.owner.lastName}` :
        null
    },
    shouldShowOwner() {
      return this.ownerName && this.reservation?.owner?.email &&
        this.$store.state.user.email !== this.reservation.owner.email
    },
  },
  methods: {
    hide() {
      this.$refs[this.id].hide()
    },
    cancelReservation() {
      this.state = 'processing'
      this.$http.delete(`/api/reservations/${this.reservation.reservationId}`)
        .then(() => {
          this.$emit('success')
          this.hide()
        })
        .catch(error => {
          if (error.response) {
            switch (error.response.status) {
              case 401: // unauthorized
                this.hide()
                return
              case 404: // not found
                this.state = 'failedNotFound'
                break;
              default:
                this.state = 'failedForOtherReason'
                break;
            }
          } else {
            this.state = 'failedDueToResponseTimeout'
          }
          this.$emit('failure')
        })
    },
    show() {
      this.state = 'confirming'
      this.$refs[this.id].show()
    },
  },
};
</script>
