<template>
  <div class="row justify-content-center h-100 w-100">
    <div class="col-md-7 col-lg-7 col-xl-5 d-flex align-items-center">
      <img
        src="https://mdbootstrap.com/img/Photos/new-templates/bootstrap-login-form/draw2.svg"
        class="img-fluid"
        alt="Phone image"
      >
    </div>
    <div class="col-md-5 col-lg-4 col-xl-3">
      <form @submit.prevent="onSubmit">
        <div class="mb-2">
          <h3 class="text-center">Profil użytkownika</h3>
          <p class="text-center lh-sm">
            <span class="fs-5 fw-bold">{{ $store.getters['user/fullName'] }}</span><br />
            <span class="text-muted fs-6">{{ $store.state.user.email }}</span>
          </p>
        </div>

        <!-- First name -->
        <span class="text-danger" v-if="firstNameWarning">
          <b-icon-exclamation-triangle /> {{ firstNameWarning }}
        </span>
        <div class="align-items-center input-group flex-nowrap mb-2">
          <div class="col-11 d-flex">
            <input
              aria-describedby="myProfileFormFirstNameLabel"
              aria-label="FirstName"
              class="form-control disabled"
              placeholder="Imię"
              type="text"
              v-model="firstName"
              :disabled="!firstNameEditActive"
              @input="onFirstNameInput"
            />
            <div class="input-group-text p-0">
              <button
                type="button"
                :class="`btn btn-${firstNameEditActive ? 'secondary' : 'light'}`"
                @click="firstNameEditActive = !firstNameEditActive"
              >
                Imię <b-icon-pencil-square />
              </button>
            </div>
          </div>
          <b-icon-asterisk class="col-1 text-danger" v-if="firstNameChanged" />
        </div>

        <!-- Last name -->
        <span class="text-danger" v-if="lastNameWarning">
          <b-icon-exclamation-triangle /> {{ lastNameWarning }}
        </span>
        <div class="align-items-center input-group flex-nowrap mb-2">
          <div class="col-11 d-flex">
            <input
              aria-describedby="myProfileFormLastNameLabel"
              aria-label="LastName"
              class="form-control"
              placeholder="Nazwisko"
              type="text"
              v-model="lastName"
              :disabled="!lastNameEditActive"
              @input="onLastNameInput"
            />
            <div class="input-group-text p-0">
              <button
                type="button"
                :class="`btn btn-${lastNameEditActive ? 'secondary' : 'light'}`"
                @click="lastNameEditActive = !lastNameEditActive"
              >
                Nazwisko <b-icon-pencil-square />
              </button>
            </div>
          </div>
          <b-icon-asterisk class="col-1 text-danger" v-if="lastNameChanged" />
        </div>

        <!-- Phone number -->
        <span class="text-danger" v-if="phoneNumberWarning">
          <b-icon-exclamation-triangle /> {{ phoneNumberWarning }}
        </span>
        <div class="align-items-center input-group flex-nowrap mb-2">
          <div class="col-11 d-flex">
            <span class="input-group-text">+48</span>
            <input
              aria-describedby="myProfileFormLastPhoneNumberLabel"
              aria-label="PhoneNumber"
              class="form-control"
              placeholder=""
              type="text"
              v-model="phoneNumber"
              :disabled="!phoneNumberEditActive"
              @input="onPhoneNumberInput"
            />
            <div class="input-group-text p-0">
              <button
                type="button"
                :class="`btn btn-${phoneNumberEditActive ? 'secondary' : 'light'}`"
                @click="phoneNumberEditActive = !phoneNumberEditActive"
              >
                Nr tel. <b-icon-pencil-square />
              </button>
            </div>
          </div>
          <b-icon-asterisk class="col-1 text-danger" v-if="phoneNumberChanged" />
        </div>

        <div v-if="isAdmin">
          <hr>
          <h4 class="text-center mb-2">Powiadomienia email</h4>

          <!-- Receive reservation email notifications -->
          <div class="row align-items-center">
            <div class="col-auto">
            <input
              class="form-check-input"
              id="receiveAllReservationsEmailNotificationsInput"
              type="checkbox"
              v-model="receiveAllReservationsEmailNotifications"
            >
            </div>
            <label class="form-check-label col" for="receiveAllReservationsEmailNotificationsInput">
              o wszystkich rezerwacjach
            </label>
            <b-icon-asterisk
              class="col-auto text-danger"
              v-if="receiveAllReservationsEmailNotificationsChanged"
            />
          </div>
        </div>

        <hr>
        <!-- Submit button -->
        <div class="d-flex justify-content-center mt-4">
          <button
            type="submit"
            :class="`btn btn-primary btn-lg ${anythingChanged ? '' : 'disabled'}`"
          >
            Zaktualizuj dane
          </button>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4">
      <button class="btn btn-primary btn-lg" type="button" @click="showResetPasswordModal">
        Zmień hasło <b-icon-key-fill />
      </button>
      </div>
    </div>
    <modal-template
      header="Aktualizowanie danych użytkownika"
      id="myProfile__changeData__modal"
      ref="myProfile__changeData__modal"
    >
      <template v-slot:body>
        <div
          class="row justify-content-center"
          v-if="changeDataState === 'confirming'"
        >
          <p>
            Zamierzasz dokonać następujących zmian w swoim profilu:
            <ul>
              <li v-if="firstNameChanged">
                zmienić imię z <b>"{{ $store.state.user.firstName }}"</b> na
                <b>"{{ firstName }}"</b>
              </li>
              <li v-if="lastNameChanged">
                zmienić nazwisko z <b>"{{ $store.state.user.lastName }}"</b> na
                <b>"{{ lastName }}"</b>
              </li>
              <li v-if="phoneNumberChanged">
                zmienić nr telefonu z <b>"{{ currentPhoneNumber }}"</b> na
                <b>"{{ phoneNumber }}"</b>
              </li>
              <li v-if="receiveAllReservationsEmailNotificationsChanged">
                {{ receiveAllReservationsEmailNotifications ? "zacząć" : "zaprzestać" }}
                otrzymywać powiadomienia email o wszystkich rezerwacjach
              </li>
            </ul>
          </p>
          <p>
            Czy na pewno chcesz dokonać tych zmian?
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-if="changeDataState === 'in_progress'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
        <div
          class="row justify-content-center"
          v-if="changeDataState === 'succeeded'"
        >
          <b-icon-check-circle-fill class="text-success fs-1 me-2" />
          <p class="text-center">
            Pomyślnie zaktualizowano dane użytkownika.
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-if="changeDataState === 'failed'"
        >
          <b-icon-x-circle-fill class="text-danger fs-1 me-2" />
          <p class="text-center">
            Nie udało się zaktualizować danych użytkownika, ponieważ <b>{{ failureReason }}</b>.
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <return-button v-if="changeDataState !== 'in_progress'" @click="hideDataChangeModal" />
        <button
          type="button"
          class="btn btn-primary"
          v-if="changeDataState === 'confirming'"
          @click="updateData"
        >
          Tak
        </button>
      </template>
    </modal-template>
    <modal-template
      header="Zmiana hasła"
      id="myProfile__resetPassword__modal"
      ref="myProfile__resetPassword__modal"
    >
      <template v-slot:body>
        <div
          class="row justify-content-center"
          v-if="resetPasswordState === 'confirming'"
        >
          <p class="text-center">
            Zmiana hasła polega na wygenerowaniu jednorazowego linku do strony, na której będzie
            można dokonać zmiany hasła. Link zostanie wysłany na adres email podany podczas
            rejestracji (<b>{{ $store.state.user.email }}</b>). Link będzie ważny przez godzinę od
            momentu jego wygenerowania.
          </p>
          <p class="text-center">
            Czy wygenerować i wysłać link do zmiany hasła?
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-if="resetPasswordState === 'in_progress'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
        <div
          class="row justify-content-center"
          v-if="resetPasswordState === 'failed'"
        >
          <b-icon-x-circle-fill class="text-danger fs-1 me-2" />
          <p class="text-center">
            Nie udało się wygenerować i wysłać linku do zmiany hasła, ponieważ
            <b>{{ failureReason }}</b>.
          </p>
        </div>
      </template>
        <template v-slot:footer>
          <return-button v-if="resetPasswordState !== 'in_progress'" />
          <button
            type="button"
            class="btn btn-primary"
            v-if="resetPasswordState === 'confirming'"
            @click="sendPasswordResetLink"
          >
            Tak
          </button>
        </template>
    </modal-template>
  </div>
  <success-toast
    message="Link został wysłany"
    :id="`myProfile__sendPasswordResetLink__successToast`"
    :ref="`myProfile__sendPasswordResetLink__successToast`"
  />
</template>

<script>
import {
  BIconAsterisk,
  BIconCheckCircleFill,
  BIconExclamationTriangle,
  BIconKeyFill,
  BIconPencilSquare,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import ModalTemplate from '../components/ModalTemplate.vue'
import ReturnButton from '../components/ReturnButton.vue'
import SuccessToast from '../components/SuccessToast.vue'

import utils from '../libs/utils'

export default {
  name: 'MyProfile',
  components: {
    BIconAsterisk,
    BIconCheckCircleFill,
    BIconExclamationTriangle,
    BIconKeyFill,
    BIconPencilSquare,
    BIconXCircleFill,
    ModalTemplate,
    ReturnButton,
    SuccessToast,
  },
  data() {
    return {
      changeDataState: 'confirming',
      firstName: new String(this.$store.state.user.firstName),
      firstNameEditActive: false,
      firstNameWarning: '',
      lastName: new String(this.$store.state.user.lastName),
      lastNameEditActive: false,
      lastNameWarning: '',
      phoneNumber: this.$store.state.user.phoneNumber ? this.$store.state.user.phoneNumber : '',
      phoneNumberEditActive: false,
      phoneNumberWarning: '',
      receiveAllReservationsEmailNotifications:
        this.$store.state.user.receiveAllReservationsEmailNotifications,
      resetPasswordState: 'confirming',
    }
  },
  computed: {
    currentPhoneNumber() {
      return this.$store.state.user.phoneNumber ? this.$store.state.user.phoneNumber : ''
    },
    firstNameChanged() {
      return this.firstName != this.$store.state.user.firstName
    },
    lastNameChanged() {
      return this.lastName != this.$store.state.user.lastName
    },
    phoneNumberChanged() {
      return this.phoneNumber != this.currentPhoneNumber
    },
    receiveAllReservationsEmailNotificationsChanged() {
      return this.receiveAllReservationsEmailNotifications !=
        this.$store.state.user.receiveAllReservationsEmailNotifications
    },
    anythingChanged() {
      return this.firstNameChanged || this.lastNameChanged || this.phoneNumberChanged ||
        this.receiveAllReservationsEmailNotificationsChanged
    },
    isAdmin() {
      const user = this.$store.state.user
      return user.roles && user.roles.some(v => (v === 'ROLE_ADMIN'))
    }
  },
  methods: {
    hideDataChangeModal() {
      this.$refs['myProfile__changeData__modal'].hide()
    },
    hideResetPasswordModal() {
      this.$refs['myProfile__resetPassword__modal'].hide()
    },
    onFirstNameInput() {
      this.firstNameWarning = ''
      this.firstName = utils.toUppercaseFirstWordLetter(this.firstName)
    },
    onPhoneNumberInput() {
      this.phoneNumber = utils.normalizePhoneNumber(this.phoneNumber)
      this.phoneNumberWarning = this.phoneNumber.length === 0 || this.phoneNumber.length === 9 ?
          null : 'Numer musi mieć 9 cyfr'
    },
    onLastNameInput() {
      this.lastNameWarning = ''
      this.lastName = utils.toUppercaseFirstWordLetter(this.lastName)
    },
    onSubmit() {
      this.firstName = utils.normalizeName(this.firstName)
      this.lastName = utils.normalizeName(this.lastName)

      let formValid = true;
      if (!this.firstName) {
        formValid = false
        this.firstNameWarning = 'Imię nie może być puste'
      }
      if (!this.lastName) {
        formValid = false
        this.lastNameWarning = 'Nazwisko nie może być puste';
      }
      if (this.phoneNumberWarning) {
        formValid = false
      }
      if (formValid && this.anythingChanged) {
        this.showDataChangeModal()
      }
    },
    sendPasswordResetLink() {
      this.resetPasswordState = 'in_progress'
      this.$http.post(
        '/api/send_password_reset_email',
        {
          email: this.$store.state.user.email,
        }
      ).then(() => {
        this.$refs['myProfile__sendPasswordResetLink__successToast'].show()
        this.hideResetPasswordModal()
      }).catch(error => {
        if (error.response) {
          switch (error.response.status) {
            case 401: // unauthorized
              this.hideResetPasswordModal()
              return
            default:
              this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
              break
          }
        } else {
          this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
        }
        this.resetPasswordState = 'failed'
      })
    },
    showDataChangeModal() {
      this.changeDataState = 'confirming'
      this.$refs['myProfile__changeData__modal'].show()
    },
    showResetPasswordModal() {
      this.resetPasswordState = 'confirming'
      this.$refs['myProfile__resetPassword__modal'].show()
    },
    updateData() {
      const parameters = {};
      if (this.firstNameChanged) {
        parameters.firstName = this.firstName;
      }
      if (this.lastNameChanged) {
        parameters.lastName = this.lastName;
      }
      if (this.phoneNumberChanged) {
        parameters.phoneNumber = this.phoneNumber
      }
      if (this.receiveAllReservationsEmailNotificationsChanged) {
        parameters.isAllReservationsObserver = this.receiveAllReservationsEmailNotifications
      }
      this.changeDataState = 'in_progress'
      this.$http.put(`/api/users/${this.$store.state.user.id}`, parameters)
        .then(response => {
          if (response.data) {
            this.$store.commit('user/setUserData', response.data)
            this.changeDataState = 'succeeded'
            return
          }

          this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
          this.changeDataState = 'failed'
        })
        .catch(error => {
          if (error.response) {
            switch (error.response.status) {
              case 401: // unauthorized
                this.hideDataChangeModal()
                return
              default:
                this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
                break
            }
          } else {
            this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
          }
          this.changeDataState = 'failed'
        })
    },
  },
}
</script>
