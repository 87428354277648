<template>
  <modal-template header="Rezerwowanie" :id="id" :ref="id">
    <template v-slot:body>
      <div class="text-center" v-if="state === 'failedReserving'">
        <p><b-icon-x-circle-fill class="text-danger fs-1" /></p>
        <p>Rezerwacja obiektu nie powiodła się ponieważ {{ failureReason }}.</p>
      </div>
      <div v-else>
        <div class="text-center mb-2">
          Rezerwujesz obiekt <b>{{ facilityName }}</b>, na dzień
          <b>{{ printableDate }}</b>, od godziny
          <b>{{ startHour }}</b>.
        </div>
        <div class="mb-2" >
          <label :for="`${id}__durationSelect`">
            Długość rezerwacji:
          </label>
          <select
            class="form-select"
            v-model="selectedDuration"
            :disabled="state === 'processingReservation' ? true : null"
            :id="`${id}__durationSelect`"
          >
            <option value="1" selected>30 minut</option>
            <option
              v-for="(_, i) in Array(availableSubsequentSlotsCount)"
              :key="i"
              :value="i + 2"
            >
              {{ durationOptionName(i) }}
            </option>
          </select>
        </div>
        <div class="mb-2" v-if="isAdmin">
          <label :for="`${id}__userSelect`" class="form-label">Rezerwuj dla użytkownika:</label>
          <user-select :id="`${id}__userSelect`" v-model="reserveForUser" />
        </div>
        <div class="mb-2" v-if="isAdmin">
          <label :for="`${id}__additionalInfo`" class="form-label">Dodatkowe informacje:</label>
          <textarea
            class="form-control"
            :id="`${id}__additionalInfo`"
            maxlength="127"
            v-model="additionalInfo"
          />
        </div>
        <div
          class="row justify-content-center"
          v-if="state === 'processingReservation'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        type="button"
        class="btn btn-secondary"
        data-bs-dismiss="modal"
        v-if="state === 'selectingDuration'"
      >
        Anuluj
      </button>
      <button
        type="button"
        class="btn btn-primary"
        v-if="state === 'selectingDuration'"
        @click="reserveSlot"
      >
        <div class="d-flex align-items-center">
          <span class="me-1">Rezerwuj</span><b-icon-calendar-2-plus-fill/>
        </div>
      </button>
      <return-button v-if="state === 'failedReserving'" />
    </template>
  </modal-template>
</template>

<script>
import {
  BIconCalendar2PlusFill,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import auth from '@/libs/auth'
import utils from '@/libs/utils'

import ModalTemplate from './ModalTemplate.vue'
import ReturnButton from './ReturnButton.vue'
import UserSelect from './UserSelect.vue'

export default {
  name: 'ReservingModal',
  components: {
    BIconCalendar2PlusFill,
    BIconXCircleFill,
    ModalTemplate,
    ReturnButton,
    UserSelect,
  },
  props: {
    availableSubsequentSlotsCount: {
      type: Number,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
    facilityName: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    startSlotIdx: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      additionalInfo: '',
      failureReason: '',
      selectedDuration: 1,
      state: 'selectingDuration',
      processingReservation: false,
      reserveForUser: null,
    }
  },
  watch: {
    availableSubsequentSlotsCount() {
      if (this.selectedDuration > this.availableSubsequentSlotsCount + 1) {
        this.selectedDuration = this.availableSubsequentSlotsCount + 1
      }
    }
  },
  emits: [
    'success',
    'failure',
  ],
  computed: {
    isAdmin() {
      const user = this.$store.state.user
      return user.roles && user.roles.some(v => (v === 'ROLE_ADMIN'))
    },
    startHour() {
      return utils.slotIdxToPrintableHour(this.startSlotIdx)
    },
    printableDate() {
      return utils.printableDate(this.date)
    },
  },
  methods: {
    durationOptionName(i) {
      return `${Math.floor(i / 2) + 1} godz.${i % 2 == 0 ? '' : ' 30 min.'}`
    },
    hide() {
      this.$refs[this.id].hide()
    },
    reserveSlot() {
      const startTime = new Date(this.date)
      startTime.setUTCHours(Math.floor(this.startSlotIdx / 2) + 7)
      startTime.setUTCMinutes(this.startSlotIdx % 2 == 0 ? 0 : 30)
      const endTime = new Date(startTime)
      endTime.setUTCMinutes(endTime.getUTCMinutes() + this.selectedDuration * 30)
      const params = {
        startTime: startTime.toISOString().slice(0, -8),
        endTime: endTime.toISOString().slice(0, -8),
        facility: `/api/facilities/${this.facilityName}`,
      }
      if (this.additionalInfo) {
        params.additionalInfo = this.additionalInfo
      }
      if (this.reserveForUser) {
        params.owner = `/api/users/${this.reserveForUser.id}`
        params.reservedBy = `/api/users/${auth.getUserData().id}`
      } else {
        params.owner = `/api/users/${auth.getUserData().id}`
      }
      this.state = 'processingReservation'
      this.$http.post('/api/reservations', params)
        .then(() => {
          this.$emit('success')
          this.hide()
        })
        .catch((error) => {
          if (error.response) {
            switch (error.response.status) {
              case 401: // unauthorized
                this.hide()
                return
              case 409: // conflict
                this.failureReason = 'obiekt został już zarezerwowany w tym terminie. '
                                      + 'Wybierz inny termin'
                break;
              default:
                this.failureReason = 'wystąpił wewnętrzny błąd w systemie. Spróbuj ponownie za '
                                     + 'chwilę'

            }
          } else {
            this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
          }
          this.state = 'failedReserving'
          this.$emit('failure')
        })
    },
    show() {
      this.state = 'selectingDuration'
      this.reserveForUser = null
      this.$refs[this.id].show()
    },
  },
};
</script>
