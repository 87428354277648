<template>
  <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
    <div
      aria-atomic="true"
      aria-live="assertive"
      class="toast hide"
      role="alert"
      :ref="`${id}__toast`"
    >
      <div class="toast-header bg-success text-white">
        <h4><b-icon-check-circle-fill class="me-2" /></h4>
        <strong class="me-auto">{{ message }}</strong>
        <button
          aria-label="Close"
          class="btn-close btn-close-white"
          data-bs-dismiss="toast"
          type="button">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BIconCheckCircleFill,
} from 'bootstrap-icons-vue'

export default {
  name: 'SuccessToast',
  components: {
    BIconCheckCircleFill,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },
  methods: {
    show() {
      this.$bootstrap.Toast.getOrCreateInstance(this.$refs[`${this.id}__toast`]).show()
    },
  },
};
</script>
