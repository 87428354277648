<template>
  <div class="container-fluid d-flex flex-column vh-100 p-0">
    <div
      class="flex-grow-0 flex-shrink-1"
    >
      <NavBar />
    </div>
    <div
      class="flex-grow-1 flex-shrink-1 justify-content-center border p-2 h-100 overflow-auto"
      style="border-radius: 10px;"
    >
      <router-view></router-view>
    </div>
    <footer
      class="flex-grow-0
             flex-shrink-1
             align-items-center
             border
             bg-secondary bg-gradient"
    >
      <div class="text-center text-white">
        <small>
          Copyright &copy; {{ new Date().getFullYear() }}, Tomasz Maj
          <a class="link-light" href="mailto:majektom@gmail.com">majektom@gmail.com</a>
        </small>
      </div>
    </footer>
  </div>
</template>
<script>
import NavBar from './components/NavBar.vue'

export default {
  name: 'App',
  components: {
    NavBar,
  },
  created() {
    document.title = process.env.VUE_APP_TITLE
  }
}
</script>
