<template>
  <button
    type="button"
    class="btn btn-secondary"
    data-bs-dismiss="modal"
  >
    <div class="d-flex align-items-center">
      <span class="me-1">Powrót</span><b-icon-arrow-90deg-left/>
    </div>
  </button>
</template>

<script>
import {
  BIconArrow90degLeft,
} from 'bootstrap-icons-vue'

export default {
  name: 'ReturnButton',
  components: {
    BIconArrow90degLeft,
  },
  props: {
  },
  methods: {
  },
};
</script>
