<template>
  <div class="container h-100 w-100">
    <div class="row d-flex justify-content-center h-100">
      <div class="col-md-8 col-lg-6 col-xl-5">
        <form class="mx-1 mx-md-4" @submit.prevent="onPasswordChange">
          <div class="mb-4">
            <h3 class="text-center">Zmiana hasła</h3>
            <p class="text-center">
              Zmieniasz hasło użytkownika posługującego się adresem email <b>{{ email }}</b>.
            </p>
          </div>

          <span class="text-danger" v-if="password1Warning">
            <b-icon-exclamation-triangle /> {{ password1Warning }}
          </span>
          <password-input
            class="mb-2"
            id="changePasswordFormPassword1"
            label="Nowe hasło"
            v-model="password1"
            @update:modelValue="onPassword1Input"
          />

          <span class="text-danger" v-if="password2Warning">
            <b-icon-exclamation-triangle /> {{ password2Warning }}
          </span>
          <password-input
            class="mb-2"
            id="changePasswordFormPassword2"
            label="Powtórz hasło"
            v-model="password2"
            @update:modelValue="onPassword2Input"
          />

          <div class="d-flex justify-content-center mt-4">
            <button type="submit" class="btn btn-primary btn-lg">Zmień hasło</button>
          </div>
        </form>
      </div>
    </div>
    <modal-template
      header="Zmienianie hasła"
      id="passwordChange__modal"
      ref="passwordChange__modal"
    >
      <template v-slot:body>
        <div
          class="row justify-content-center"
          v-if="passwordChangeState === 'in_progress'"
        >
          <div class="spinner-border text-info" role="status" />
        </div>
        <div
          class="row justify-content-center"
          v-if="passwordChangeState === 'succeeded'"
        >
          <b-icon-check-circle-fill class="text-success fs-1 me-2" />
          <p class="text-center">
            Hasło zostało zmienione.
          </p>
        </div>
        <div
          class="row justify-content-center"
          v-if="passwordChangeState === 'failed'"
        >
          <b-icon-x-circle-fill class="text-danger fs-1 me-2" />
          <p class="text-center">
            Niestety nie udało się zmienić hasła, ponieważ
            <b>{{ failureReason }}</b>.
          </p>
        </div>
      </template>
      <template v-slot:footer>
        <return-button
          v-if="passwordChangeState !== 'in_progress'"
          @click="onPasswordChangeModalButtonClick"
        />
      </template>
    </modal-template>
  </div>
</template>

<script>
import {
  BIconCheckCircleFill,
  BIconExclamationTriangle,
  BIconXCircleFill,
} from 'bootstrap-icons-vue'

import ModalTemplate from '../components/ModalTemplate.vue'
import PasswordInput from '../components/PasswordInput.vue'
import ReturnButton from '../components/ReturnButton.vue'

export default {
  name: 'ChangePassword',
  components: {
    BIconCheckCircleFill,
    BIconExclamationTriangle,
    BIconXCircleFill,
    ModalTemplate,
    PasswordInput,
    ReturnButton,
  },
  props: {
    email: {
      type: String,
      required: true,
    },
    verificationUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      password1: '',
      password1Warning: null,
      password2: '',
      password2Warning: null,
      passwordChangeState: 'idle',
    }
  },
  methods: {
    onPassword1Input() {
      this.password1Warning = null
      if (this.password2 && this.password1 !== this.password2) {
        this.password2Warning = 'Hasła muszą być identyczne'
      } else {
        this.password2Warning = null
      }
    },
    onPassword2Input() {
      if (this.password2 && this.password1 !== this.password2) {
        this.password2Warning = 'Hasła muszą być identyczne'
      } else {
        this.password2Warning = null
      }
    },
    onPasswordChangeModalButtonClick() {
      this.$refs['passwordChange__modal'].hide()
      const succeeded = this.passwordChangeState === 'succeeded'
      this.passwordChangeState = 'idle'
      if (succeeded) {
        this.$router.replace('/')
      }
    },
    onPasswordChange() {
      let formValid= true;
      if (!this.password1) {
        formValid = false
        this.password1Warning = 'Hasło nie może być puste'
      }
      if (this.password1 !== this.password2) {
        formValid = false
      }
      if (formValid) {
        this.passwordChangeState = 'in_progress'
        this.$refs['passwordChange__modal'].show()
        this.$http.post(
          this.verificationUrl,
          {
            password: this.password1,
          }
        ).then(() => {
          this.passwordChangeState = 'succeeded'
        }).catch(error  => {
          if (error.response) {
            switch (error.response.status) {
              case 401: // unauthorized
                this.$refs['passwordChange__modal'].hide()
                return
              case 410: // gone
                this.failureReason = 'ważność linku do zmiany hasła wygasła'
                break
              default:
                this.failureReason = 'wystąpił wewnętrzny błąd w systemie'
                break
            }
          } else {
            this.failureReason = 'serwer nie odpowiedział w wyznaczonym czasie'
          }
          this.passwordChangeState = 'failed'
        })
      }
    },
  },
}
</script>
