<template>
  <div :id="id">
    <table class="table table-striped align-middle text-center border">
      <thead class="table-info">
        <tr>
          <th>Data</th>
          <th>Początek</th>
          <th>Koniec</th>
          <th>Długość</th>
          <th>Obiekt</th>
          <th v-if="!past">Akcje</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="requestingData">
          <td :colspan="past ? 5 : 6">
            <div class="spinner-border text-info" role="status" />
          </td>
        </tr>
        <tr v-else-if="failedToRequestData">
          <td :colspan="past ? 5 : 6">
            <b-icon-emoji-frown class="text-danger fs-2" /><br />Błąd pobierania danych.
          </td>
        </tr>
        <tr v-else-if="reservations.length === 0">
          <td :colspan="past ? 5 : 6">
            Brak rezerwacji
          </td>
        </tr>
        <tr v-else v-for="(reservation, i) in reservations" :key="i">
          <td>
            {{ printableDate(reservation.startTime) }}
          </td>
          <td>
            {{ printableTime(reservation.startTime) }}
          </td>
          <td>
            {{ printableTime(reservation.endTime) }}
          </td>
          <td>
            {{ duration(reservation.startTime, reservation.endTime) }}
          </td>
          <td>
            {{ reservation.facilityName }}
          </td>
          <td v-if="!past">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              title="Odwołaj"
              @click="onCancelReservationClicked(reservation)"
            >
              <div class="d-flex align-items-center">
                <b-icon-trash/>
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination
      :currentPage="currentPage"
      :pagesCount="pagesCount"
      @onPageChosen="onPageChosen"
    />
  </div>
  <canceling-modal
    :date="cancelationInfo.date"
    :facilityName="cancelationInfo.facilityName"
    :id="cancelingModalId"
    :ref="cancelingModalId"
    :reservationId="cancelationInfo.reservationId"
    :startSlotIdx="cancelationInfo.reservationStartSlotIdx"
    :reservedSlotsCount="cancelationInfo.reservedSlotsCount"
    @failure="onUnsuccessfulCancelation"
    @success="onSuccessfulCancelation"
  />
  <success-toast
    message="Pomyśnie odwołano rezerwację"
    :id="cancelationSuccessToastId"
    :ref="cancelationSuccessToastId"
  />
</template>

<script>
import {
  BIconEmojiFrown,
  BIconTrash,
} from 'bootstrap-icons-vue'

import CancelingModal from './CancelingModal.vue'
import Pagination from './Pagination.vue'
import SuccessToast from './SuccessToast.vue'

import utils from '../libs/utils'

export default {
  name: 'ReservationList',
  components: {
    BIconEmojiFrown,
    BIconTrash,
    CancelingModal,
    Pagination,
    SuccessToast,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    past: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    cancelingModalId() {
      return `${this.id}__cancelingModal`
    },
    cancelationSuccessToastId() {
      return `${this.id}__cancelationSuccessToast`
    },
  },
  created() {
    this.getReservations()
  },
  data() {
    return {
      cancelationInfo: {
        date: new Date(),
        facilityName: '',
        reservationId: 0,
        reservationStartSlotIdx: 0,
        reservedSlotsCount: 0,
      },
      currentPage: 1,
      failedToRequestData: false,
      pagesCount: 1,
      requestingData: false,
      reservations: [],
    }
  },
  methods: {
    getReservations() {
      this.requestingData = true
      this.failedToRequestData = false
      const params = {
        'order[startTime]': this.past ? 'desc' : 'asc',
        'owner.id': this.$store.state.user.id,
        page: this.currentPage,
      }
      params[`startTime[${this.past ? 'before' : 'strictly_after'}]`] = 'now'
      this.$http.get(
        '/api/reservations',
        {
          headers: {
            accept: 'application/ld+json'
          },
          params
        })
        .then(response => {
          if (response.data) {
            const hydraMembers = response.data['hydra:member']
            this.reservations = hydraMembers.map(v => {
              const res = {
                startTime: new Date(v.startTime),
                endTime: new Date(v.endTime),
                facilityName: decodeURIComponent(v.facility.split('/').pop()),
                reservationId: v.id,
              }
              res.reservationStartSlotIdx =
                Math.floor((res.startTime.getHours() - 7) * 2 + res.startTime.getMinutes() / 30)
              res.reservedSlotsCount =
                Math.floor((res.endTime.getHours() - 7) * 2 + res.endTime.getMinutes() / 30)
                  - res.reservationStartSlotIdx
              return res
            })
            let pagesCount = 0
            const hydraView = response.data['hydra:view']
            if (hydraView) {
              const hydraViewLast = hydraView['hydra:last']
              if (hydraViewLast) {
                const pageParam = /(\?|&)page=([0-9]+)/.exec(hydraViewLast)
                if (pageParam) {
                  pagesCount = parseInt(pageParam[2])
                }
              }
            }
            this.pagesCount = pagesCount
          }
          this.requestingData = false
        })
        .catch(() => {
          this.requestingData = false
          this.failedToRequestData = true
        })
    },
    printableDate(date) {
      return utils.printableDate(date)
    },
    printableTime(date) {
      return utils.printableTime(date)
    },
    duration(startTime, endTime) {
      const diff = new Date(endTime - startTime)
      const hours = diff.getUTCHours()
      const minutes = diff.getUTCMinutes()
      return (hours > 0 ? `${hours} godz. ` : '') + (minutes > 0 ? `${minutes} min.` : '')
    },
    onCancelReservationClicked(reservation) {
      this.cancelationInfo = {
        date: reservation.startTime,
        facilityName: reservation.facilityName,
        reservationId: reservation.reservationId,
        reservationStartSlotIdx: reservation.reservationStartSlotIdx,
        reservedSlotsCount: reservation.reservedSlotsCount,
      }
      this.$refs[this.cancelingModalId].show()
    },
    onPageChosen(page) {
      this.currentPage = page
      this.getReservations()
    },
    onSuccessfulCancelation() {
      this.getReservations()
      this.$refs[this.cancelationSuccessToastId].show()
    },
    onUnsuccessfulCancelation() {
      this.getReservations()
    },
  },
}
</script>
