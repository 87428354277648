import auth from '../libs/auth'

function doSetUserData(state, userData) {
    state.firstName = userData ? userData.firstName : null
    state.lastName = userData ? userData.lastName : null
    state.email = userData ? userData.email : null
    state.id = userData ? userData.id : null
    state.roles = userData ? userData.roles : null
    state.phoneNumber = userData ? userData.phoneNumber : null
    state.receiveAllReservationsEmailNotifications = userData ? userData.isAllReservationsObserver : null
    return state
}

const userData = auth.getUserData()
const state = doSetUserData({}, userData)

const getters = {
    fullName: state => (
        state.firstName && state.lastName ? `${state.firstName} ${state.lastName}` : null
    ),
}

const mutations = {
    setUserData(state, userData) {
        if (userData) {
            auth.storeUserData(userData)
        } else {
            auth.clearUserData()
        }
        doSetUserData(state, userData)
    },
}

export const user = {
    namespaced: true,
    state,
    getters,
    mutations,
}
